import { React, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useOktaAuth } from "@okta/okta-react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NonEmvMerchant from "./NonEmvMerchant";
import NonEmvModalAddMerchant from "./NonEmvModalAddMerchant.js";
import { getDomain } from "./Domain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "30%",
    height: "40%",
    outline: "none",
  },
  addMerchantFont: {
    fontSize: 16,
  },
  formStyle: {
    marginLeft: "30px",
    marginTop: "20px",
  },
  merchantId: {
    width: "90%",
    height: "3.5rem",
    marginBottom: "1rem",
  },
  searchMerchantId: {
    width: "17%",
  },
  externalId: {
    width: "90%",
    height: "45px",
  },
  saveBtn: {
    marginRight: "1rem",
    width: "5rem",
  },
  cancelBtn: {
    marginRight: "1rem",
    width: "5rem",
  },
  nonEmvFont: {
    fontSize: 16,
  },
  formSearch: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const NonEmv = (props) => {
  const classes = useStyles();
  const { authState, oktaAuth } = useOktaAuth();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  let [nonEmvFlag, setNonEmvFlag] = useState(false);
  let [nonEmvMerchantArray, setNonEmvMerchantArray] = useState([]);

  let [originalMerchantArray, setOriginalMerchantArray] = useState([
    ...nonEmvMerchantArray,
  ]);

  let [searchedMerchant, setSearchMerchant] = useState("");
  let [searchedValue, setSearchedValue] = useState("");

  let [refresh, setRefresh] = useState(false);

  const [value, setValue] = useState("merchantId");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [loading, setLoading] = useState(false);

  let nonEmvMerchantDetail = null;

  useEffect(() => {
    if (authState.isAuthenticated) {
      setLoading(true);
      setOpenBackdrop(!openBackdrop);
      const accessToken = oktaAuth.getAccessToken();
      fetch(`${getDomain()}tenants/${props.tenantName}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((object) => {
          setPage(0);
          setLoading(false);
          setOpenBackdrop(false);
          if (object.configTypes.length > 0) {
            object.configTypes.forEach((item) => {
              if (item.type === "NON-EMV" && item.merchants.length > 0) {
                setNonEmvFlag(true);
                setNonEmvMerchantArray(item.merchants);
                setOriginalMerchantArray(item.merchants);
              }
            });
          }
        });
    }
  }, [authState, refresh]);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const addNonEmvMerchant = (nonEmvMerchantData) => {
    let tempMerchantArray = [...nonEmvMerchantArray];

    tempMerchantArray.unshift({
      id: nonEmvMerchantData.merchantId,
      merchantExternalIds: nonEmvMerchantData.externalId,
      password: nonEmvMerchantData.password,
      status: "active",
    });

    setNonEmvMerchantArray([...nonEmvMerchantArray]);
    setRefresh(!refresh);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  /* Get the radio value */
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleRefesh = (event) => {
    const accessToken = oktaAuth.getAccessToken();
    fetch(`${getDomain()}tenants/${props.tenantName}/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((object) => {
        if (object.configTypes.length > 0) {
          object.configTypes.some((item) => {
            if (item.type === "NON-EMV" && item.merchants.length > 0) {
              setNonEmvMerchantArray(item.merchants);
              setOriginalMerchantArray(item.merchants);
              filteredItem(searchedValue, item.merchants);
            }
          });
        }
      });
  };

  const searchMerchantHandler = (e) => {
    let searchedItem = e.target.value;

    filteredItem(searchedItem);
    setSearchedValue(searchedItem);
  };

  const filteredItem = (searchedItem, tempArray) => {
    if (tempArray) {
      originalMerchantArray = [...tempArray];
    }

    if (value === "merchantId") {
      let filterArray = originalMerchantArray.filter((item) => {
        return item.id === searchedItem;
      });

      if (filterArray.length !== 0) {
        setNonEmvMerchantArray(filterArray);
        setPage(0);
      } else if (filterArray.length === 0 && searchedItem.length !== 0) {
        setNonEmvMerchantArray([]);
      } else {
        setNonEmvMerchantArray([...originalMerchantArray]);
      }
      setSearchMerchant(searchedItem);
    } else {
      let filteredArray = [];
      originalMerchantArray.forEach((merchantItem) => {
        merchantItem.merchantExternalIds.forEach((externalItem) => {
          if (externalItem === searchedItem) {
            filteredArray = [{ ...merchantItem }];
          }
        });
      });

      if (filteredArray.length !== 0) {
        setNonEmvMerchantArray(filteredArray);
        setPage(0);
      } else if (filteredArray.length === 0 && searchedItem.length !== 0) {
        setNonEmvMerchantArray([]);
      } else {
        setNonEmvMerchantArray([...originalMerchantArray]);
      }
      setSearchMerchant(searchedItem);
    }
  };

  /* This method is used to disable / enable merchant*/
  const updateNonEmvMerchantStatus = (status, index) => {
    let newArray1 = nonEmvMerchantArray.map((item, indexItem) => {
      if (indexItem === index) {
        item.status = status === true ? "active" : "disabled";
        return item;
      }

      return item;
    });
    setNonEmvMerchantArray([...newArray1]);
  };

  const editNonEmvMerchant = (updatedMerchant, index) => {
    nonEmvMerchantArray.map((item, indexItem) => {
      if (indexItem === index && updatedMerchant.merchantId !== undefined) {
        item.id = updatedMerchant.merchantId;
      }
      if (indexItem === index && updatedMerchant.externalId !== undefined) {
        item.merchantExternalIds[0] = updatedMerchant.externalId;
      }
      return item;
    });
  };

  const nonEmvHeader = (
    <Box
      display="flex"
      justifyContent="space-between"
      style={{ marginBottom: "1rem" }}
      height="4rem"
    >
      <Box width="85%">
        <form className={classes.formSearch}>
          <TextField
            required
            size="small"
            name="searchMerchantId"
            label="Filter by"
            variant="outlined"
            id="searchMerchant"
            value={searchedMerchant}
            className={`searchMerchant`}
            onChange={searchMerchantHandler}
            style={{ marginRight: "1rem" }}
          />
          <RadioGroup row name="merchant" value={value} onChange={handleChange}>
            <FormControlLabel
              value="merchantId"
              control={<Radio id={`searchedNonEmvMerchantValue`} />}
              label="Merchant Id"
              className={`searchMerchantRadio`}
            />
            <FormControlLabel
              value="external"
              control={<Radio id={`searchedNonEmvExternalIdValue`} />}
              label="Idemia Location Id"
              className={`searchExternalRadio`}
            />
          </RadioGroup>
          <Button
            color="primary"
            variant="contained"
            className={`merchantRefresh`}
            onClick={handleRefesh}
          >
            Refresh
          </Button>
        </form>
      </Box>
      <Button
        variant="contained"
        color="primary"
        type="button"
        style={{ height: "40px" }}
        onClick={handleOpen}
        className={`addMerchantButton`}
      >
        + Add Merchant
      </Button>
    </Box>
  );

  if (nonEmvFlag) {
    nonEmvMerchantDetail = (
      <NonEmvMerchant
        nonEmvMerchantArray={nonEmvMerchantArray}
        editNonEmvMerchant={editNonEmvMerchant}
        tenantName={props.tenantDetail.name}
        handleChangePage={handleChangePage}
        page={page}
        updateNonEmvMerchantStatus={updateNonEmvMerchantStatus}
        rowsPerPage={rowsPerPage}
      />
    );
  } else {
    nonEmvMerchantDetail = (
      <Box>
        <Typography variant="subtitle1" className={`noMerchantText`}>
          Non-EMV is not configured, please click <strong>Add Merchant</strong>{" "}
          button to configre non-EMV
        </Typography>
      </Box>
    );
  }

  return (
    <Box mt="2rem">
      {loading && (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress size={36} className={classes.buttonProgress} />
        </Backdrop>
      )}
      {nonEmvHeader}
      {nonEmvMerchantDetail}
      <NonEmvModalAddMerchant
        tenantName={props.tenantDetail.name}
        open={open}
        handleClose={handleClose}
        addNonEmvMerchant={addNonEmvMerchant}
        nonEmvMerchantArray={nonEmvMerchantArray}
      />
    </Box>
  );
};

export default NonEmv;
