import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import NonEmvModalEditMerchant from "./NonEmvModalEditMerchant";
import { getDomain } from "./Domain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  tenantNameCell: {
    fontWeight: "bold",
  },
  tableHeader: {
    backgroundColor: "#d3d3d3",
  },
  columnCell: {
    width: "20%",
  },
  tableCellFont: {
    fontSize: 16,
  },
  editBtn: {
    width: "5rem",
    marginRight: "30px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
}));

const NonEmvMerchant = (props) => {
  const { oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const [openEditMerchantModal, setOpenEditMerchantModal] = useState(false);
  const [merchantIndex, setMerchantIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleChangePage = (event, newPage) => {
    props.handleChangePage(newPage);
  };

  /* This method is used to handle the merchant status when 
  a user switches from active to disabled, and vice versa. 
  Whenever you call e.target.checked, the switch will be triggered.
  */
  const handleChange = (index, e) => {
    const accessToken = oktaAuth.getAccessToken();
    setLoading(true);
    setOpenBackdrop(!openBackdrop);

    fetch(
      `${getDomain()}tenants/${
        props.tenantName
      }/configTypes/NON-EMV/merchants/${
        props.nonEmvMerchantArray[props.page * 10 + index].id
      }`,
      {
        method: "put",
        body: JSON.stringify({
          status: e.target.checked ? "active" : "disabled",
        }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((object) => {
        if (object.status === "Success") {
          props.updateNonEmvMerchantStatus(
            !e.target.checked,
            props.page * 10 + index
          );
          setLoading(false);
          setOpenBackdrop(false);
        } else {
          return Promise.reject(object.error);
        }
      })
      .catch((error) => {});
  };

  let editNonEmvMerchantHandler = (merchantIndex, e) => {
    setOpenEditMerchantModal(true);
    setMerchantIndex(merchantIndex);
  };

  const closeEditModalHandler = () => {
    setOpenEditMerchantModal(false);
  };

  return (
    <Paper className={classes.paper}>
      <TableContainer component={Paper}>
        {loading && (
          <Backdrop className={classes.backdrop} open={openBackdrop}>
            <CircularProgress size={36} className={classes.buttonProgress} />
          </Backdrop>
        )}
        <Table className={`${classes.table} listOfMerchant`}>
          <colgroup>
            <col className={classes.columnCell} />
            <col className={classes.columnCell} />
            <col className={classes.columnCell} />
            <col className={classes.columnCell} />
            <col className={classes.columnCell} />
          </colgroup>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={classes.tableCell}>
                Merchant Status
              </TableCell>
              <TableCell className={classes.tableCell}>Merchant Id</TableCell>
              <TableCell className={classes.tableCell}>
                Idemia Location Id
              </TableCell>
              <TableCell className={classes.tableCell}>Password</TableCell>
              <TableCell className={classes.tableCell}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.nonEmvMerchantArray
              .slice(
                props.page * props.rowsPerPage,
                props.page * props.rowsPerPage + props.rowsPerPage
              )
              .map((row, index) => (
                <TableRow key={row.id} className={`merchantRow`}>
                  <TableCell className={`merchantStatusToggle`}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={row.status === "active" ? true : false}
                          onChange={(e) => handleChange(index, e)}
                          value={
                            row.status === "active" ? "Active" : "Inactive"
                          }
                          id={`merchantStatusToggle-${index}`}
                          color="primary"
                        />
                      }
                      label={row.status === "active" ? "Active" : "Inactive"}
                    />
                  </TableCell>
                  <TableCell className={`${classes.tableCellFont} merchantId-${index}`}>
                    <strong>{row.id}</strong>
                  </TableCell>
                  <TableCell className={`${classes.tableCellFont} externalId-${index}`}>
                    {row.merchantExternalIds[0]}
                  </TableCell>
                  <TableCell className={`${classes.tableCellFont} password-${index}`}>
                    ******
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      className={`${classes.editBtn} editTenantBtn`}
                      onClick={(e) => editNonEmvMerchantHandler(index, e)}
                      disabled={row.status === "disabled" ? true : false}
                    >
                      Edit
                    </Button>
                    {openEditMerchantModal === true &&
                    merchantIndex === index ? (
                      <NonEmvModalEditMerchant
                        tenantName={props.tenantName}
                        closeEditModalHandler={closeEditModalHandler}
                        openEditMerchantModal={openEditMerchantModal}
                        nonEmvMerchantArray={props.nonEmvMerchantArray}
                        updatedMerchant={row}
                        editNonEmvMerchant={props.editNonEmvMerchant}
                        merchantIndex={merchantIndex}
                        page={props.page}
                      />
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5]}
        count={props.nonEmvMerchantArray.length}
        rowsPerPage={10}
        page={props.page}
        onChangePage={handleChangePage}
        nextIconButtonProps={{  'id': 'nextPage' }}
        backIconButtonProps={{  'id': 'previousPage' }}
        classes={{
          caption: `paginationText`
        }}
      />
    </Paper>
  );
};

export default NonEmvMerchant;
