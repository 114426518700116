import { React, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ModalAddTenant from "./ModalAddTenant";
import TenantTablePage from "./TenantTablePage";
import { getDomain } from "./Domain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  addTenantBtn: {
    fontSize: 14,
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Tenant = (props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [flagTable, setFlagTable] = useState("");
  const [tenantArray, setTenantArray] = useState([]);
  let tablePage = null;
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [loading, setLoading] = useState(false);

  /* Open a Tenant Modal */
  const handleOpen = () => {
    setOpen(true);
  };

  /* close a Tenant Modal */
  const handleClose = () => {
    setOpen(false);
  };

  /* This method is used to set the tenant name and tenant description when user add them in Add Tenant modal
  This method is passed to ModalAddTenant and will be called over there. We add the tenantName and tenant Description
  at the beginning of the array. */
  const saveClick = (tenantInput) => {
    let tempArray = [...tenantArray];
    setFlagTable("table");
    tempArray.unshift({ ...tenantInput, status: "active" });
    setTenantArray([...tempArray]);
  };

  /* This method is for front end only. This method is in progress since it will be integrated with the backend for
  tenant status.*/
  const updateTenantStatus = (index, tenantStatus) => {
    let newTenantArray = tenantArray.map((item, indexItem) => {
      if (indexItem === index) {
        item.status = tenantStatus === true ? "active" : "disabled";
        return item;
      }
      return item;
    });
    setTenantArray([...newTenantArray]);
  };

  const editTenantProcessor = (tenantIndex, tenantObj) => {
    tenantArray.map((item, indexItem) => {
      if (indexItem === tenantIndex) {
        item.name = tenantObj.tenantName;
        item.description = tenantObj.tenantDescription;
        return item;
      }
      return item;
    });
  };

  /* This will call an API to get all tenants at the beginning  */
  useEffect(() => {
    if (authState.isAuthenticated) {
      const accessToken = oktaAuth.getAccessToken();
      setLoading(true);
      setOpenBackdrop(!openBackdrop);
      fetch(`${getDomain()}tenants`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((object) => {
          setTenantArray([...object]);
          setLoading(false);
          setOpenBackdrop(false);
          if (object.length !== 0) {
            setFlagTable("table");
          }
        });
    }
  }, [authState]);

  /* tenantHeader is a variable to hold the header tenant that includes text and button, and it is used to avoid DRY*/
  const tenantHeader = (
    <Box display="flex" justifyContent="space-between" my={2}>
      <Typography variant="h5">
        <strong>MANAGE TENANT</strong>
      </Typography>
      <Button
        variant="contained"
        color="primary"
        type="button"
        onClick={handleOpen}
        className={`${classes.addTenantBtn} addTenantButton`}
      >
        + Add Tenant
      </Button>
    </Box>
  );

  /* If flagtable is 1, it will list a table of tenants
     else just display a message that asks a user to click Add Tenant. 
  */
  if (flagTable === "table") {
    tablePage = (
      <Box>
        {tenantHeader}
        <TenantTablePage
          tenantArray={tenantArray}
          updateTenantStatus={updateTenantStatus}
          editTenantProcessor={editTenantProcessor}
        />
      </Box>
    );
  } else {
    tablePage = (
      <Box>
        {tenantHeader}
        <Typography
          variant="subtitle1"
          className={`${classes.addTenantBtn} addTenantMessage`}
        >
          Please click <strong>+Add Tenant</strong> button to add tenant
        </Typography>
      </Box>
    );
  }

  return (
    <Box my="7rem" mx="1.25rem">
      {loading && (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress size={36} className={classes.buttonProgress} />
        </Backdrop>
      )}
      <ModalAddTenant
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open}
        saveClick={saveClick}
        tenantArray={tenantArray}
      />
      <Box>{tablePage}</Box>
    </Box>
  );
};

export default Tenant;
