import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useOktaAuth } from "@okta/okta-react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import ModalEditTenant from "./ModalEditTenant";
import { getDomain } from "./Domain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  tenantNameCell: {
    fontWeight: "bold",
  },
  tableHeader: {
    backgroundColor: "#d3d3d3",
  },
  columnCell: {
    width: "25%",
  },
  viewTenantButton: {
    width: "5rem",
    marginRight: "30px",
  },
  switchStyle: {
    width: "7rem",
  },
  tenantRow: {
    height: "6rem",
  },
  tenantStatusStyle: {
    fontSize: "12px",
    paddingTop: "1rem",
    display: "block",
    position: "relative",
    height: "4rem",
    fontWeight: "bold",
  },
  warningStatusFont: {
    color: "red",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  EditTenantButton: {
    fontSize: "18px"
  }
}));

let TenantTablePage = (props) => {
  const classes = useStyles();

  const [tenantIndex, setTenantIndex] = useState(0);
  const [openEditTenantModal, setOpenEditTenantModal] = useState(false);
  const { oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  /* This method is used to update the tenant status */
  const handleChange = (index, event) => {
    const accessToken = oktaAuth.getAccessToken();
    setLoading(true);
    setOpenBackdrop(!openBackdrop);
    fetch(`${getDomain()}tenants/${props.tenantArray[index].name}`, {
      method: "put",
      body: JSON.stringify({
        status: event.target.checked ? "active" : "disabled",
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((object) => {
        if (object.status === "Success") {
          props.updateTenantStatus(index, !event.target.checked);
          setLoading(false);
          setOpenBackdrop(false);
        } else {
          return Promise.reject(object.error);
        }
      })
      .catch((error) => {});
  };

  const editTenantHandler = (tenantIndex, e) => {
    setOpenEditTenantModal(true);
    setTenantIndex(tenantIndex);
  };

  /* This method will olose the configuration modal */
  const closeEditModalHandler = () => {
    setOpenEditTenantModal(false);
  };

  return (
    <TableContainer component={Paper}>
      {loading && (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress size={36} className={classes.buttonProgress} />
        </Backdrop>
      )}
      <Table className={`${classes.table} listOfTenant`}>
        <colgroup>
          <col className={classes.columnCell} />
          <col className={classes.columnCell} />
          <col className={classes.columnCell} />
          <col className={classes.columnCell} />
        </colgroup>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell className={classes.tableCell}>Tenant Status</TableCell>
            <TableCell className={classes.tableCell}>Tenant Name</TableCell>
            <TableCell className={classes.tableCell}>
              Tenant Description
            </TableCell>
            <TableCell className={classes.tableCell}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tenantArray.map((row, index) => (
            <TableRow
              key={row.name}
              className={`${classes.tenantRow} tenantRow`}
            >
              <TableCell>
                <div className={classes.tenantStatusStyle}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={row.status === "active" ? true : false}
                        onChange={(e) => handleChange(index, e)}
                        value={row.status === "active" ? "Active" : "Inactive"}
                        color="primary"
                        id={`tenantStatusToggle-${index}`}
                      />
                    }
                    className={`${classes.switchStyle}`}
                    label={row.status === "active" ? "Active" : "Inactive"}
                  />
                </div>
              </TableCell>
              <TableCell className={`tenantName`}>
                <strong>{row.name}</strong>
              </TableCell>
              <TableCell className={`tenantDescription`}>
                {row.description}
              </TableCell>
              <TableCell>
                <Button
                  component={Link}
                  to={`/${row.name}`}
                  variant="contained"
                  color="primary"
                  disabled={row.status === "disabled" ? true : false}
                  className={`${classes.viewTenantButton} viewTenantBtn`}
                >
                  View
                </Button>
                <Button
                  type="button"
                  display="inline"
                  className={`${classes.EditTenantButton} EditTenantBtn`}
                  disabled={row.status === "disabled" ? true : false}
                  onClick={(e) => editTenantHandler(index, e)}
                >
                  <i class="fas fa-pencil-alt" />
                </Button>
                {openEditTenantModal === true && tenantIndex === index ? (
                  <ModalEditTenant
                    updatedTenant={row}
                    tenantArray={props.tenantArray}
                    tenantIndex={tenantIndex}
                    openEditTenantModal={openEditTenantModal}
                    closeEditModalHandler={closeEditModalHandler}
                    editTenantProcessor={props.editTenantProcessor}
                  />
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TenantTablePage;
