import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import WarningError from "./WarningError";
import FormValidation from "./FormValidation";
import { getDomain } from "./Domain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  tenantNameCell: {
    fontWeight: "bold",
  },
  tableHeader: {
    backgroundColor: "#d3d3d3",
  },
  columnCell: {
    width: "16%",
  },
  lastCell: {
    width: "16%",
  },
  columnCellSpecial: {
    width: "25%",
  },
  tableCellStyle: {
    fontSize: 16,
  },
  btnEdit: {
    width: "30%",
    marginRight: "30px",
    marginBottom: "10px",
  },
  passwordStyle: {
    width: "9rem",
    marginRight: "20px",
    marginTop: ".5rem",
  },
  confirmPasswordStyle: {
    width: "9rem",
    height: "3rem",
    marginTop: ".5rem",
  },
  inputStyle: {
    height: "3rem",
    marginTop: "0.75rem",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  input: {
    "&::placeholder": {
      fontSize: 8
    }
  },
}));

const PaymentConfigtable = (props) => {
  const { oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const [updatePaymentConfig, setUpdatePaymentConfig] = useState({
    terminalId: "",
    username: "",
    password: "",
    confirmPassword: "",
    customerCode: "",
  });

  const [paymentConfigErrorState, setPaymentConfigErrorState] = useState({
    terminalIdError: false,
    customerCodeError: false,
    usernameError: false,
    confirmPasswordError: false,
    password: false,
  });

  const [fieldFlag, setFieldFlag] = useState({
    terminalId: false,
    customerCode: false,
    username: false,
    password: false,
    confirmPassword: false,
    editCheck: false,
  });

  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  /* This method is used to handle filling payment configuration fields*/
  const inputHandler = (e) => {
    setUpdatePaymentConfig({
      ...updatePaymentConfig,
      [e.target.name]: e.target.value,
    });

    props.handleEditMessage("noEdit");

    if (e.target.value.length >= 0 && e.target.name === "terminalId") {
      setFieldFlag({ ...fieldFlag, terminalId: true, editCheck: true });
      setPaymentConfigErrorState({
        ...paymentConfigErrorState,
        terminalIdError: false,
      });
    }

    if (e.target.value.length >= 0 && e.target.name === "customerCode") {
      setFieldFlag({ ...fieldFlag, customerCode: true, editCheck: true });
      setPaymentConfigErrorState({
        ...paymentConfigErrorState,
        customerCodeError: false,
      });
    }

    if (e.target.value.length >= 0 && e.target.name === "username") {
      setFieldFlag({ ...fieldFlag, username: true, editCheck: true });
      setPaymentConfigErrorState({
        ...paymentConfigErrorState,
        usernameError: false,
      });
    }
    if (e.target.value.length > 0 && e.target.name === "confirmPassword") {
      setFieldFlag({ ...fieldFlag, confirmPassword: true, editCheck: true });
      setPaymentConfigErrorState({
        ...paymentConfigErrorState,
        passwordError: false,
        confirmPasswordError: false,
      });
    }

    if (e.target.value.length > 0 && e.target.name === "password") {
      setFieldFlag({ ...fieldFlag, password: true, editCheck: true });
      setUpdatePaymentConfig({
        ...updatePaymentConfig,
        [e.target.name]: e.target.value,
      });
      setPaymentConfigErrorState({
        ...paymentConfigErrorState,
        passwordError: false,
        confirmPasswordError: false,
      });
    } else if (e.target.value.length === 0 && e.target.name === "password") {
      setFieldFlag({ ...fieldFlag, password: false, editCheck: true });
      setPaymentConfigErrorState({
        ...paymentConfigErrorState,
        passwordError: false,
        confirmPasswordError: false,
      });
    }
  };

  /* This method is used to handle the validation for field form when editing
  payment configuration. It will return true, if all fields are filled correctly
  ,otherwise, a false will be returned.
  */
  const isPaymentConfigValid = (e) => {
    let output = false;

    if (
      FormValidation.terminalIdValid(updatePaymentConfig.terminalId) &&
      FormValidation.customerCodeValid(updatePaymentConfig.customerCode) &&
      FormValidation.usernameValid(updatePaymentConfig.username) &&
      updatePaymentConfig.confirmPassword === updatePaymentConfig.password
    ) {
      setPaymentConfigErrorState({
        ...paymentConfigErrorState,
        confirmPasswordError: false,
      });
      output = true;
    } else {
      setPaymentConfigErrorState({
        ...paymentConfigErrorState,
        terminalIdError: !FormValidation.terminalIdValid(
          updatePaymentConfig.terminalId
        ),
        customerCodeError: !FormValidation.customerCodeValid(
          updatePaymentConfig.customerCode
        ),
        usernameError: !FormValidation.usernameValid(
          updatePaymentConfig.username
        ),
        confirmPasswordError:
          updatePaymentConfig.confirmPassword !== updatePaymentConfig.password,
      });
    }
    return output;
  };

  /* Whenever a user turn on edit mode, we set whatever payment config to updatePaymentConfig, 
  and handle it
  */
  const editHandler = (paymentConfigIndex, e) => {
    if (editMode === false) {
      props.handlePaymentEdit(paymentConfigIndex, "on");
      setUpdatePaymentConfig({
        ...props.paymentConfigList[paymentConfigIndex],
        password: "",
        confirmPassword: "",
      });
      setEditMode(true);
    } else {
      props.handleEditMessage("editInProgress");
    }
  };

  /* This method is used to disable/re-enable the payment config, 
  When editMode is on, and handleEditMessage is triggered when users 
  switch the payment config status
  */
  const handleChange = (paymentConfigIndex, e) => {
    const accessToken = oktaAuth.getAccessToken();

    if (editMode === false) {
      fetch(
        `${getDomain()}tenants/${props.tenantName}/configTypes/EMV/merchants/${
          props.merchantArray[props.page * 10 + props.merchantIndex].id
        }/paymentConfigs/${
          props.paymentConfigList[paymentConfigIndex].terminalId
        }`,
        {
          method: "put",
          body: JSON.stringify({
            status: e.target.checked ? "active" : "disabled",
          }),
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((object) => {
          if (object.status === "Success") {
            props.handlePaymentStatus(!e.target.checked, paymentConfigIndex);
          } else {
            return Promise.reject(object.error);
          }
        })
        .catch((error) => {});
    } else {
      props.handleEditMessage("editInProgress");
    }
  };

  /* This method will handle the edit when user clicks save button
  It will take care of the following situations:
  1. When users updated with a duplicate terminal Id
  2. When users updated with the same terminal Id
  3. When users type different terminal Id, and also take care other field checks
  4. When users did not make any changes 
  
  */
  const editSaveHandler = (paymentConfigIndex, e) => {
    const accessToken = oktaAuth.getAccessToken();
    let result = {
      terminalId: updatePaymentConfig.terminalId,
      username: updatePaymentConfig.username,
      customerCode: updatePaymentConfig.customerCode,
    };

    if (fieldFlag.password) {
      result.password = updatePaymentConfig.password;
    }

    let duplicatedTerminalId = props.paymentConfigList.some((item) => {
      return (
        item.terminalId === updatePaymentConfig.terminalId &&
        props.paymentConfigList[paymentConfigIndex].terminalId !==
          updatePaymentConfig.terminalId
      );
    });

    if (editMode === true) {
      if (!fieldFlag.editCheck) {
        setEditMode(false);
        props.handlePaymentEdit(
          paymentConfigIndex,
          "close",
          updatePaymentConfig
        );
        setFieldFlag({ ...fieldFlag, editCheck: false });
        props.handleEditMessage("noEdit");
      } else if (duplicatedTerminalId && fieldFlag.terminalId) {
        props.handleEditMessage(
          "editInProgress",
          `Payment configuration with terminal id '${updatePaymentConfig.terminalId}' already exists for merchant.`
        );
      } else if (
        isPaymentConfigValid() &&
        props.paymentConfigList[paymentConfigIndex].terminalId ===
          updatePaymentConfig.terminalId &&
        props.paymentConfigList[paymentConfigIndex].customerCode ===
          updatePaymentConfig.customerCode &&
        props.paymentConfigList[paymentConfigIndex].username ===
          updatePaymentConfig.username &&
        !fieldFlag.password
      ) {
        setEditMode(false);
        props.handlePaymentEdit(
          paymentConfigIndex,
          "close",
          updatePaymentConfig
        );
        setFieldFlag({ ...fieldFlag, editCheck: false });
        props.handleEditMessage("noEdit");
      } else if (isPaymentConfigValid()) {
        setLoading(true);
        setOpenBackdrop(!openBackdrop);
        fetch(
          `${getDomain()}tenants/${
            props.tenantName
          }/configTypes/EMV/merchants/${
            props.merchantArray[props.page * 10 + props.merchantIndex].id
          }/paymentConfigs/${
            props.paymentConfigList[paymentConfigIndex].terminalId
          }`,
          {
            method: "put",
            body: JSON.stringify(result),
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((object) => {
            if (object.status === "Success") {
              setLoading(false);
              setOpenBackdrop(false);
              props.handlePaymentEdit(
                paymentConfigIndex,
                "close",
                updatePaymentConfig
              );

              setEditMode(false);
              props.handleEditMessage("success");
              setFieldFlag({
                ...fieldFlag,
                terminalId: false,
                editCheck: false,
              });
            } else {
              return Promise.reject(object.error);
            }
          })
          .catch((error) => {
            props.handleEditMessage("editInProgress", error.errorMessage);
            setEditMode(true);
          });
      }
    }
  };

  return (
    <TableContainer component={Paper}>
      {loading && (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress size={36} className={classes.buttonProgress} />
        </Backdrop>
      )}
      <Table className={`${classes.table} listOfPaymentConfiguration`}>
        <colgroup>
          <col className={classes.columnCell} />
          <col className={classes.columnCell} />
          <col className={classes.columnCell} />
          <col className={classes.columnCell} />
          <col className={classes.columnCellSpecial} />
          <col className={classes.lastCell} />
        </colgroup>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell className={classes.tableCell}>
              Payment Config Status
            </TableCell>
            <TableCell className={classes.tableCell}>Terminal Id</TableCell>
            <TableCell className={classes.tableCell}>Customer Code</TableCell>
            <TableCell className={classes.tableCell}>Username</TableCell>
            <TableCell className={classes.tableCell}>Password</TableCell>
            <TableCell className={classes.tableCell}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.paymentConfigList.map((obj, index) => (
            <TableRow
              key={obj.terminalId}
              className={`paymentConfigurationRow`}
            >
              <TableCell className={`paymentConfigurationStatusToggle`}>
                <FormControlLabel
                  disabled={props.merchantStatus === "disabled" ? true : false}
                  control={
                    <Switch
                      checked={obj.status === "active" ? true : false}
                      onChange={(e) => handleChange(index, e)}
                      value={obj.status === "active" ? "Active" : "Inactive"}
                      id={`paymentConfigStatusToggle-${index}`}
                      color="primary"
                    />
                  }
                  label={obj.status === "active" ? "Active" : "Inactive"}
                />
              </TableCell>
              <TableCell className={`${classes.tableCellStyle} terminalId-${index}`}>
                {obj.edit ? (
                  <Grid item>
                    <TextField
                      required
                      size="small"
                      name="terminalId"
                      label="Terminal Id"
                      variant="outlined"
                      className={classes.inputStyle}
                      onChange={inputHandler}
                      error={paymentConfigErrorState.terminalIdError}
                      helperText={WarningError.terminalIdError(
                        paymentConfigErrorState.terminalIdError
                      )}
                      value={
                        fieldFlag.terminalId
                          ? updatePaymentConfig.terminalId
                          : obj.terminalId
                      }
                    />
                  </Grid>
                ) : (
                  <Box>{obj.terminalId}</Box>
                )}
              </TableCell>
              <TableCell className={`${classes.tableCellStyle} customerCode-${index}`}>
                {obj.edit ? (
                  <Grid item>
                    <TextField
                      required
                      size="small"
                      name="customerCode"
                      label="Customer Code"
                      variant="outlined"
                      className={classes.inputStyle}
                      onChange={inputHandler}
                      error={paymentConfigErrorState.customerCodeError}
                      helperText={WarningError.customerCodeError(
                        paymentConfigErrorState.customerCodeError
                      )}
                      value={
                        fieldFlag.customerCode
                          ? updatePaymentConfig.customerCode
                          : obj.customerCode
                      }
                    />
                  </Grid>
                ) : (
                  <Box>{obj.customerCode}</Box>
                )}
              </TableCell>
              <TableCell className={`${classes.tableCellStyle} username-${index}`}>
                {obj.edit ? (
                  <Grid item>
                    <TextField
                      required
                      size="small"
                      name="username"
                      label="Username"
                      variant="outlined"
                      onChange={inputHandler}
                      className={classes.inputStyle}
                      error={paymentConfigErrorState.usernameError}
                      helperText={WarningError.usernameError(
                        paymentConfigErrorState.usernameError
                      )}
                      value={
                        fieldFlag.username
                          ? updatePaymentConfig.username
                          : obj.username
                      }
                    />
                  </Grid>
                ) : (
                  <Box>{obj.username}</Box>
                )}
              </TableCell>
              <TableCell className={`${classes.tableCellStyle} password-${index}`}>
                {obj.edit ? (
                  <Grid item>
                    <TextField
                      required
                      type="password"
                      size="small"
                      name="password"
                      label="Password"
                      placeholder="Leave empty for unchanged password"
                      variant="outlined"
                      onChange={inputHandler}
                      className={classes.passwordStyle}
                      value={updatePaymentConfig.password}
                      error={paymentConfigErrorState.passwordError}
                      helperText={WarningError.passwordError(
                        paymentConfigErrorState.passwordError
                      )}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        classes: {
                          input: classes.input
                        }
                      }}
                    />
                    <TextField
                      required
                      type="password"
                      placeholder="Leave empty for unchanged password"
                      size="small"
                      name="confirmPassword"
                      label="Confirm Password"
                      variant="outlined"
                      className={classes.confirmPasswordStyle}
                      onChange={inputHandler}
                      error={paymentConfigErrorState.confirmPasswordError}
                      helperText={WarningError.confirmPasswordError(
                        paymentConfigErrorState.confirmPasswordError
                      )}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        classes: {
                          input: classes.input
                        }
                      }}
                      value={updatePaymentConfig.confirmPassword}
                    />
                  </Grid>
                ) : (
                  <Box className={`passwordValue-${index}`}>******</Box>
                )}
              </TableCell>
              <TableCell className={classes.tableCellStyle}>
                {obj.edit ? (
                  <Box>
                    <Button
                      type="button"
                      style={{ fontSize: "18px" }}
                      onClick={(e) => editSaveHandler(index, e)}
                      className={`save`}
                    >
                      <i class="fas fa-save" />
                    </Button>
                  </Box>
                ) : (
                  <Button
                    disabled={
                      props.merchantStatus === "disabled"
                        ? true
                        : obj.status === "disabled"
                        ? true
                        : false
                    }
                    onClick={(e) => editHandler(index, e)}
                    className={`editPaymentConfiguration`}
                  >
                    <i class="fas fa-pencil-alt" />
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentConfigtable;
