import { React, useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MerchantTable from "./MerchantTable";
import CircularProgress from "@material-ui/core/CircularProgress";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ModalAddMerchant from "./ModalAddMerchant";
import { getDomain } from "./Domain";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "30%",
    height: "40%",
    outline: "none",
  },
  addMerchantFont: {
    fontSize: 16,
  },
  formStyle: {
    marginLeft: "30px",
    marginTop: "20px",
  },
  merchantId: {
    width: "90%",
    height: "3.5rem",
    marginBottom: "1rem",
  },
  searchMerchantId: {
    width: "17%",
  },
  externalId: {
    width: "90%",
    height: "45px",
  },
  saveBtn: {
    marginRight: "1rem",
    width: "5rem",
  },
  cancelBtn: {
    marginRight: "1rem",
    width: "5rem",
  },

  formSearch: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const MerchantPage = (props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  let [merchantArray, setMerchantArray] = useState([]);

  let [originalMerchantArray, setOriginalMerchantArray] = useState([
    ...merchantArray,
  ]);

  let [merchantDetailFlag, setMerchantDetailFlag] = useState(false);

  let merchantDetail = null;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let [refresh, setRefresh] = useState(false);

  const [value, setValue] = useState("merchantId");
  let [searchedMerchant, setSearchMerchant] = useState("");
  let [searchedValue, setSearchedValue] = useState("");
  const [groupId, setGroupId] = useState("");
  const [loading, setLoading] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  /* This hook is used to render all the merchants at the beginning, and whenever a refresh value is changed, 
  this hook will be called to fetch all the merchants from the backend.
  */
  useEffect(() => {
    if (authState.isAuthenticated) {
      setLoading(true);
      setOpenBackdrop(!openBackdrop);
      const accessToken = oktaAuth.getAccessToken();
      fetch(`${getDomain()}tenants/${props.tenantName}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          return response.json();
        })

        .then((object) => {
          setPage(0);
          setLoading(false);
          setOpenBackdrop(false);
          if (object.configTypes.length > 0) {
            object.configTypes.forEach((item) => {
              if (
                item.type === "EMV" &&
                item.merchants.length > 0 &&
                item.groupId
              ) {
                setMerchantArray(item.merchants);
                setOriginalMerchantArray(item.merchants);

                setMerchantDetailFlag(true);
                setGroupId(item.groupId);

                item.merchants.forEach((merArray) => {
                  merArray.paymentConfigurations.forEach((paymentObj) => {
                    paymentObj.edit = false;
                  });
                });
              } else if (item.type === "EMV" && item.merchants.length === 0) {
                setMerchantDetailFlag(false);
                setGroupId(item.groupId);
              }
            });
          }
        });
    }
  }, [authState, refresh]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  /* This method will handle the merchant list, we will create an array of objects where each object includes merchant Id, and an array of external Id
  Whenever a merchant is added, it will be updated into the database, and get a list of merchants from the api. We set refresh so everytime, we 
  add a merchant, a list of merchants will be updated from the database.
  */
  let processMerchantList = (merchantInputField) => {
    let tempMerchantArray = [...merchantArray];

    tempMerchantArray.unshift({
      id: merchantInputField.merchantId,
      merchantExternalIds: [].concat(merchantInputField.externalId),
      paymentConfigurations: [],
      status: "active",
    });

    setMerchantArray([...tempMerchantArray]);

    setMerchantDetailFlag(true);

    setOriginalMerchantArray([
      ...merchantArray,
      {
        id: merchantInputField.merchantId,
        merchantExternalIds: [].concat(merchantInputField.externalId),
        paymentConfigurations: [],
        status: "active",
      },
    ]);
    setRefresh(!refresh);
  };

  /* This method is used to set an updated externalId list when a user add External Id to the list*/
  const saveListExternalId = (externalIdList, index) => {
    merchantArray.map((item, indexItem) => {
      if (indexItem === index) {
        item.merchantExternalIds = externalIdList;
        return item;
      }
      return item;
    });
  };

  const deleteListExternalId = (externalIdList, index) => {
    merchantArray.map((item, indexItem) => {
      if (indexItem === index) {
        item.merchantExternalIds = externalIdList;
        return item;
      }
      return item;
    });
  };

  /* This method is used to set an updated payment config list when a user add payment config to the list*/
  const saveListPaymentConfig = (paymentConfigList, index) => {
    merchantArray.map((item, indexItem) => {
      if (indexItem === index) {
        item.paymentConfigurations = paymentConfigList;
        return item;
      }
      return item;
    });
  };

  /* This method is used to disable / enable merchant*/
  const updateMerchantStatus = (status, index) => {
    let newArray1 = merchantArray.map((item, indexItem) => {
      if (indexItem === index) {
        item.status = status === true ? "active" : "disabled";
        return item;
      }

      return item;
    });
    setMerchantArray([...newArray1]);
  };

  /* Whenever a user types something, we call filteredItem to
  handle filter the input
  */

  const searchMerchantHanlder = (e) => {
    let searchedItem = e.target.value;
    filteredItem(searchedItem);
    setSearchedValue(searchedItem);
  };

  /* This method will handle the filter for the item you put 
    with radio button as merchant Id or external Id to get 
    the list of merchants. We manipulate different scenario
    and set merchant array to that filter array. 
    */

  const filteredItem = (searchedItem, tempArray) => {
    if (tempArray) {
      originalMerchantArray = [...tempArray];
    }

    if (value === "merchantId") {
      let filterArray = originalMerchantArray.filter((item) => {
        return item.id === searchedItem;
      });

      if (filterArray.length !== 0) {
        setMerchantArray(filterArray);
        setPage(0);
      } else if (filterArray.length === 0 && searchedItem.length !== 0) {
        setMerchantArray([]);
      } else {
        setMerchantArray([...originalMerchantArray]);
      }
      setSearchMerchant(searchedItem);
    } else {
      let filteredArray = [];
      originalMerchantArray.forEach((merchantItem) => {
        merchantItem.merchantExternalIds.forEach((externalItem) => {
          if (externalItem === searchedItem) {
            filteredArray = [{ ...merchantItem }];
          }
        });
      });

      if (filteredArray.length !== 0) {
        setMerchantArray(filteredArray);
        setPage(0);
      } else if (filteredArray.length === 0 && searchedItem.length !== 0) {
        setMerchantArray([]);
      } else {
        setMerchantArray([...originalMerchantArray]);
      }
      setSearchMerchant(searchedItem);
    }
  };

  /* This method is used to refresh to get a list of tenants and 
  its nested objects such as merchantId, externalIdList, payment config list. 
  We manipulate it to get an array of merchants, then filtered the item
  you type for filtered against this array by calling a method filteredItem.
  
  */
  const handleRefesh = (event) => {
    const accessToken = oktaAuth.getAccessToken();
    fetch(`${getDomain()}tenants/${props.tenantName}/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((object) => {
        if (object.configTypes.length > 0) {
          object.configTypes.some((item) => {
            if (item.type === "EMV" && item.merchants.length > 0) {
              setMerchantArray(item.merchants);
              setOriginalMerchantArray(item.merchants);
              filteredItem(searchedValue, item.merchants);
            }
          });
        }
      });
  };

  /* Get the radio value */
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  /* handle pagination*/
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  /* merchantHeader is a variable to hold the merchant header that includes text and button, and it is used to avoid DRY*/
  const merchantHeader = (
    <Box
      display="flex"
      justifyContent="space-between"
      style={{ marginBottom: "1rem" }}
      height="4rem"
    >
      {loading && (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress size={36} className={classes.buttonProgress} />
        </Backdrop>
      )}
      <Typography variant="h5">
        <strong>Group Id: </strong>
        {groupId}
      </Typography>
      <Box width="70%">
        <form className={classes.formSearch}>
          <TextField
            required
            size="small"
            name="searchMerchantId"
            label="Filter by"
            variant="outlined"
            id="searchMerchant"
            onChange={searchMerchantHanlder}
            value={searchedMerchant}
            className={`searchMerchant`}
            style={{ marginRight: "1rem" }}
          />
          <RadioGroup row name="merchant" value={value} onChange={handleChange}>
            <FormControlLabel
              value="merchantId"
              control={<Radio id={`searchedEmvMerchantValue`} />}
              label="Merchant Id"
              className={`searchMerchantRadio`}
            />
            <FormControlLabel
              value="external"
              control={<Radio id={`searchedEmvExternalIdValue`} />}
              label="Idemia Location Id"
              className={`searchExternalRadio`}
            />
          </RadioGroup>
          <Button
            color="primary"
            variant="contained"
            onClick={handleRefesh}
            className={`merchantRefresh`}
          >
            Refresh
          </Button>
        </form>
      </Box>
      <Button
        variant="contained"
        color="primary"
        type="button"
        style={{ height: "40px" }}
        onClick={handleOpen}
        className={`addMerchantButton`}
      >
        + Add Merchant
      </Button>
    </Box>
  );

  /* If merchantDetailFlag is true, this means that a merchant is added successfully, then a merchant table is displayed.
     Otherwise, a merchant header is shown only. 
  */

  if (merchantDetailFlag) {
    merchantDetail = (
      <Box>
        {merchantHeader}
        <MerchantTable
          merchantArray={merchantArray}
          saveListExternalId={saveListExternalId}
          saveListPaymentConfig={saveListPaymentConfig}
          updateMerchantStatus={updateMerchantStatus}
          tenantName={props.tenantName}
          handleChangePage={handleChangePage}
          page={page}
          rowsPerPage={rowsPerPage}
          deleteListExternalId={deleteListExternalId}
        />
      </Box>
    );
  } else {
    merchantDetail = (
      <Box>
        {merchantHeader}
        <Typography
          variant="subtitle1"
          className={`${classes.addMerchantFont} noMerchantText`}
        >
          No merchant added. Please click <strong>Add Merchant</strong> button
          to start adding merchant
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {merchantDetail}
      <ModalAddMerchant
        open={open}
        handleClose={handleClose}
        processMerchantList={processMerchantList}
        tenantName={props.tenantName}
        merchantArray={merchantArray}
      />
    </Box>
  );
};

export default MerchantPage;
