import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import WarningError from "./WarningError";
import FormValidation from "./FormValidation";
import { getDomain } from "./Domain";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "30%",
    height: "75%",
    outline: "none",
  },
  formStyle: {
    marginLeft: "30px",
    marginTop: "20px",
    marginBottom: "10px",
  },
  externalInput: {
    width: "80%",
    height: "3rem",
  },
  externalList: {
    height: "90%",
    overflowY: "auto",
  },
  closeRow: {
    marginBottom: "-40px",
  },
  btnStyle: {
    marginRight: "1rem",
    width: "5rem",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ModalExistingMerchant = (props) => {
  const { oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const [externalInput, setExternalInputField] = useState("");
  const [errorExistingExternal, setErrorExistingExternal] = useState(false);
  const [addExistingMerchantError, setAddExistingMerchantError] = useState("");
  const [addExistingMerchantErrorMsg, setAddExistingMerchantErrorMsg] =
    useState("");

  const merchantStatusState =
    props.merchantArray[props.index + props.page * 10].status;

  const [externalList, setExternalList] = useState([
    ...props.merchantArray[props.page * 10 + props.index].merchantExternalIds,
  ]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [loading, setLoading] = useState(false);
  let alert = null;

  /* This method is used to set a value for external Input and validate it */
  const externalInputHandler = (e) => {
    setExternalInputField(e.target.value);

    if (
      e.target.value.length > 0 &&
      !FormValidation.existingExternalIdValid(e.target.value)
    ) {
      setErrorExistingExternal(true);
      setAddExistingMerchantError("");
    } else {
      setErrorExistingExternal(false);
      setAddExistingMerchantError("");
    }
  };

  /* This method is used to validdate the tenant name
  Whenever the tenant name passes the validation, return tenantNameError is false, and return output as true
  */
  const isExternalIdValid = (e) => {
    let output = false;

    if (
      externalInput.length > 0 &&
      FormValidation.existingExternalIdValid(externalInput)
    ) {
      setErrorExistingExternal(false);
      output = true;
    } else if (
      externalInput.length === 0 &&
      !FormValidation.existingExternalIdValid(externalInput)
    ) {
      setErrorExistingExternal(true);
      output = false;
    }

    return output;
  };

  /* This method will add a new external Id to an existing merchant.
  Before add it to an existing merchant, it will check if there is any 
  duplicated external Id, if yes, it will populate the warning message
   */
  const plusHandler = (e) => {
    let externalPlaceholderArray = [...externalList];
    const accessToken = oktaAuth.getAccessToken();

    let duplicatedExternal = props.merchantArray.some((merchantObj) => {
      return merchantObj.merchantExternalIds.some((externalId) => {
        return externalId === externalInput;
      });
    });

    if (duplicatedExternal) {
      setAddExistingMerchantError("error");
      setAddExistingMerchantErrorMsg(
        `External Id '${externalInput}' already exists for a merchant in this configuration type.`
      );
    } else if (isExternalIdValid()) {
      setLoading(true);
      setOpenBackdrop(!openBackdrop);
      fetch(
        `${getDomain()}tenants/${props.tenantName}/configTypes/EMV/merchants/${
          props.merchantArray[props.page * 10 + props.index].id
        }/externalIds`,
        {
          method: "post",
          body: JSON.stringify({
            externalId: externalInput,
          }),
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((object) => {
          if (object.status === "Success") {
            setLoading(false);
            setOpenBackdrop(false);
            externalPlaceholderArray.unshift(externalInput);
            setExternalList([...externalPlaceholderArray]);
            props.saveListExternalId(
              externalPlaceholderArray,
              props.index + props.page * 10
            );

            setAddExistingMerchantError("success");
          } else {
            return Promise.reject(object.error);
          }
        })
        .catch((error) => {
          setAddExistingMerchantError("error");
          setAddExistingMerchantErrorMsg(error.errorMessage);
        });
    }
  };

  const deleteExternalId = (index, e) => {
    let newExternalIdList = [...externalList];
    const accessToken = oktaAuth.getAccessToken();

    if (newExternalIdList.length > 1) {
      setLoading(true);
      setOpenBackdrop(!openBackdrop);
      fetch(
        `${getDomain()}tenants/${props.tenantName}/configTypes/EMV/merchants/${
          props.merchantArray[props.page * 10 + props.index].id
        }/externalIds/${newExternalIdList[index]}`,
        {
          method: "delete",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((object) => {
          if (object.status === "Success") {
            setLoading(false);
            setOpenBackdrop(false);
            newExternalIdList.splice(index, 1);
            setExternalList(newExternalIdList);
            props.deleteListExternalId(
              newExternalIdList,
              props.index + props.page * 10
            );

            setAddExistingMerchantError("successDelete");
            setAddExistingMerchantErrorMsg("An external Id is deleted");
          } else {
            return Promise.reject(object.error);
          }
        })
        .catch((error) => {});
    } else {
      setAddExistingMerchantError("error");
      setAddExistingMerchantErrorMsg(
        "A merchant must have at least one idemia location id"
      );
    }
  };

  /* This variable will hold a list of elements for external id */
  const externalIdList = externalList.map((item, index) => (
    <Box className={`externalIdRow`}>
      <Button
        style={{ marginLeft: "-20px" }}
        disabled={merchantStatusState === "disabled" ? true : false}
        className={`delete`}
        onClick={(e) => deleteExternalId(index, e)}
      >
        <i class="fas fa-trash-alt fa-2x"></i>
      </Button>
      <Box component="div" display="inline" className={`existingExternal`}>
        {item}
      </Box>
    </Box>
  ));

  /* Error or successful message when tenant is added.*/
  if (addExistingMerchantError === "success") {
    alert = (
      <Box className={`externalIdSuccessMessage`} color="green">
        A new Idemia location is added
      </Box>
    );
  } else if (addExistingMerchantError === "successDelete") {
    alert = (
      <Box className={`externalIdSuccessMessage`} color="green">
        An Idemia location is deleted.
      </Box>
    );
  } else if (addExistingMerchantError === "error") {
    alert = (
      <Box className={`externalIdErrorMessage`} color="red">
        {addExistingMerchantErrorMsg}
      </Box>
    );
  }

  return (
    <Modal
      className={classes.modal}
      open={true}
      onClose={props.closeExistingMerchantModal}
      BackdropComponent={Backdrop}
    >
      <Box className={`${classes.paper} externalIdModal`}>
        <Box height="5%" color="#FFF" padding={1} bgcolor="primary.main">
          Manage Idemia Location Id
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="82%"
        >
          <Box>
            <form className={classes.formStyle}>
              <TextField
                size="small"
                name="externalIdInput"
                label="Type Idemia Location Id"
                variant="outlined"
                id="externalIdInput"
                className={`${classes.externalInput} externalIdInput`}
                value={externalInput}
                onChange={externalInputHandler}
                error={errorExistingExternal}
                helperText={WarningError.existingExternalIdError(
                  errorExistingExternal
                )}
                disabled={merchantStatusState === "disabled" ? true : false}
              />
              <Button
                type="button"
                size="small"
                disabled={merchantStatusState === "disabled" ? true : false}
                onClick={plusHandler}
                style={{ marginLeft: "10px", fontSize: "28px" }}
                className={`addButton`}
              >
                <i class="fas fa-plus"></i>
              </Button>
            </form>
            <Divider orientation="horizontal" />
          </Box>
          <Box style={{ margin: "10px 0px 10px 20px" }}>
            {" "}
            {externalIdList.length} idemia location id
          </Box>
          <Box
            ml={3}
            display="flex"
            height="85%"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box className={`${classes.externalList} listOfExternalId`}>
              {externalIdList}
            </Box>
            {loading && (
              <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress
                  size={36}
                  className={classes.buttonProgress}
                />
              </Backdrop>
            )}
            {alert}
            <Box className={`${classes.closeRow}`}>
              <Button
                variant="contained"
                color="Primary"
                className={`${classes.btnStyle} close`}
                onClick={props.closeExistingMerchantModal}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalExistingMerchant;
