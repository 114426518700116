let WarningError = {
    tenantNameError: tenantName => tenantName && 'invalid tenant name',
    groupIdError: groupId => groupId && 'invalid group id',
    merchantIdError: merchantId => merchantId && 'invalid merchant Id',
    externalIdError: externalId => externalId && 'invalid external Id',
    existingExternalIdError: existingExternalId => existingExternalId && 'invalid external Id',
    searchMerchantId: searchedMerchantId => searchedMerchantId && ' no result return',
    searchExternalId: searchedExternalId => searchedExternalId && ' no result return',
    terminalIdError: merchantId => merchantId && 'invalid terminal Id',
    customerCodeError: externalId => externalId && 'invalid customer code',
    usernameError: existingExternalId => existingExternalId && 'invalid username',
    passwordError: password => password && 'invalid password',
    confirmPasswordError: confirmPassword => confirmPassword && 'password mismatch'
}

export default WarningError;