import { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import WarningError from "./WarningError";
import FormValidation from "./FormValidation";
import { getDomain } from "./Domain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "30%",
    outline: "none",

    "@media (max-width: 1600px)": {
      height: "42%",
    },

    "@media (max-width: 1440px)": {
      height: "42%",
    },
  },
  formStyle: {
    marginLeft: "30px",
    marginTop: "10px",
  },
  tenantNameInput: {
    width: "auto",
    height: "55px",
  },
  tenantDescriptionInput: {
    width: "90%",
    height: "70px",
  },
  saveAddTenantBtn: {
    marginRight: "1rem",
    width: "5rem",
  },
  cancelAddTenantBtn: {
    width: "5rem",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ModalAddTenant = (props) => {
  const { oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const [tenantObj, setTenantObj] = useState({
    name: "",
    description: "",
  });
  const [formErrorState, setFormErrorState] = useState({
    tenantNameError: false,
    tenantDescriptionError: false,
  });

  const [addTenantError, setAddTenantError] = useState("");
  const [addTenantErrorMsg, setAddTenantErrorMsg] = useState("");
  const [saveStatus, setSaveStatus] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [loading, setLoading] = useState(false);

  let alert = null;

  /* This method is used to validdate the tenant name whenever a user clicks save button
  When the tenant name passes the validation,  we call the parent props, and set the fields back to empty string.
  This method also handled  duplicated itenant name
  */
  const save = (e) => {
    let duplicatedOutput = props.tenantArray.some((obj) => {
      return obj.name === tenantObj.name;
    });

    if (duplicatedOutput) {
      setAddTenantError("error");
      setAddTenantErrorMsg(
        `Tenant with tenant name '${tenantObj.name}' already exists.`
      );
    } else if (isTenantNameValid()) {
      const accessToken = oktaAuth.getAccessToken();
      setLoading(true);
      setOpenBackdrop(!openBackdrop);
      fetch(`${getDomain()}tenants`, {
        method: "post",
        body: JSON.stringify({
          tenantName: tenantObj.name,
          description: tenantObj.description,
        }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          return response.json();
        })

        .then((object) => {
          if (object.status === "Success") {
            setLoading(false);
            setOpenBackdrop(false);
            props.saveClick(tenantObj);
            setSaveStatus(true);
            setTenantObj({ name: "", description: "" });
            setAddTenantError("success");
          } else {
            return Promise.reject(object.error);
          }
        })
        .catch((error) => {
          setAddTenantError("error");
          setAddTenantErrorMsg(error.errorMessage);
        });
    }
  };

  /* This method is used to validdate the tenant name
  Whenever the tenant name passes the validation, return tenantNameError is false, and return output as true
  */
  const isTenantNameValid = (e) => {
    let output = true;

    if (
      tenantObj.name.length > 0 &&
      FormValidation.tenantNameValid(tenantObj.name)
    ) {
      setFormErrorState({ tenantNameError: false });
      output = true;
    }
    if (
      tenantObj.name.length >= 0 &&
      !FormValidation.tenantNameValid(tenantObj.name)
    ) {
      setFormErrorState({ tenantNameError: true });
      output = false;
    }

    return output;
  };

  /* This method is used to handle when the user types in the text fields tenant name and tenant description.
    For tenant name, it will validate, and set tenantNameError to be false if the validation is successful
    For tenant description, it will not do anything. 
    Any failed validation, it will set tenantnameError to be true.
  */
  const textFieldHandler = (e) => {
    setTenantObj({ ...tenantObj, [e.target.name]: e.target.value });
    setSaveStatus(false);
    setAddTenantError("");
    setAddTenantErrorMsg("");
    if (
      e.target.value.length > 0 &&
      !FormValidation.tenantNameValid(e.target.value) &&
      e.target.name === "name"
    ) {
      setFormErrorState({ tenantNameError: true });
    } else if (
      e.target.value.length >= 0 &&
      e.target.name === "description" &&
      formErrorState.tenantNameError === true
    ) {
      setFormErrorState({ tenantNameError: true });
    } else {
      setFormErrorState({ tenantNameError: false });
    }
  };

  /* This method is used to handle when the user types clicks cancel or clicks outside of the modal. 
  We reset the tenantName, and tenant description.*/
  const closeHandler = (e) => {
    setFormErrorState({
      tenantNameError: false,
      tenantDescriptionError: false,
    });
    setSaveStatus(false);
    setTenantObj({ name: "", description: "" });
    props.handleClose();
    setAddTenantError("");
    setAddTenantErrorMsg("");
  };

  /* Error or successful message when tenant is added.*/
  if (addTenantError === "success") {
    alert = (
      <Box className={`tenantSuccessMessage`} color="green">
        A new tenant is added
      </Box>
    );
  } else if (addTenantError === "error") {
    alert = (
      <Box className={`tenantErrorMessage`} color="red">
        {addTenantErrorMsg}
      </Box>
    );
  } else {
    alert = <Box>{addTenantErrorMsg}</Box>;
  }

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={closeHandler}
      id="addTenantId"
      BackdropComponent={Backdrop}
    >
      <section className={`${classes.paper} addTenantModal`}>
        <Box height="5%" color="#FFF" p={1} bgcolor="primary.main">
          Add Tenant
        </Box>
        <form className={`${classes.formStyle}`}>
          <TextField
            required
            size="small"
            name="name"
            label="Tenant Name"
            value={tenantObj.name}
            variant="outlined"
            onChange={textFieldHandler}
            className={`${classes.tenantNameInput} tenantName`}
            error={formErrorState.tenantNameError}
            helperText={WarningError.tenantNameError(
              formErrorState.tenantNameError
            )}
            id="tenantName"
            inputProps={{ maxLength: 10 }}
          />
          <br />
          <br />
          <TextField
            required
            rows={6}
            multiline={true}
            inputProps={{ maxLength: 255 }}
            label="Tenant Description"
            placeholder="Tenant Description up to 256 characters"
            name="description"
            className={`${classes.tenantDescriptionInput} tenantDescription`}
            value={tenantObj.description}
            variant="outlined"
            onChange={textFieldHandler}
          />
        </form>
        <Box
          ml="2rem"
          mr="3.25rem"
          mt="5rem"
          mb="1rem"
          height="5rem"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box></Box>
          {alert}
          {loading && (
            <Backdrop className={classes.backdrop} open={openBackdrop}>
              <CircularProgress size={36} className={classes.buttonProgress} />
            </Backdrop>
          )}
          <Box>
            <Button
              variant="contained"
              color="Primary"
              className={`${classes.saveAddTenantBtn} save`}
              onClick={save}
              disabled={saveStatus}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="Cancel"
              onClick={closeHandler}
              className={`${classes.cancelAddTenantBtn} close`}
            >
              Close
            </Button>
          </Box>
        </Box>
      </section>
    </Modal>
  );
};

export default ModalAddTenant;
