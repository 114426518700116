import React from "react";
import { OktaAuth } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback, useOktaAuth } from '@okta/okta-react';
import { Route, useHistory, Switch  } from "react-router-dom";
import CustomLoginComponent from './Login';
import getOktaConfig from './OktaConfig';
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Tenant from "./Tenant";
import TenantView from "./TenantView";
import Navbar from './Navbar';

const theme = createMuiTheme({
  typography: {
    fontSize: 12,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    justifyContent: "space-between",
  },
}));

const oktaAuth = new OktaAuth(getOktaConfig());

const App = () => {
  const history = useHistory();

  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    history.push('/login');
  };

  const classes = useStyles();


  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
    >
      <ThemeProvider theme={theme}>
        <Box className={classes.root} id="manageTenant">
          <Navbar classes={classes} />
          <main>
            <Switch>
              <Route path="/login" component={CustomLoginComponent} />
              <Route path="/login/callback" component={LoginCallback} />
              <SecureRoute exact path="/:name" component={TenantView} />
              <SecureRoute exact path="/" component={Tenant} />
            </Switch>
          </main>
        </Box>
      </ThemeProvider>
    </Security>
  );
};

export default App;
