import React from "react";
import { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import PaymentConfigtable from "./PaymentConfigTable";
import WarningError from "./WarningError";
import FormValidation from "./FormValidation";
import { getDomain } from "./Domain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "90%",
    height: "90%",
    outline: "none",
  },
  inputStyle: {
    height: "3rem",
    marginTop: "0.75rem",
  },
  btnStyle: {
    marginRight: "1rem",
    width: "5rem",
  },
  message: {
    display: "block",
    position: "absolute",
    top: "-30px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
}));

const ModalPaymentConfig = (props) => {
  const { oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const [paymentConfig, setPaymentConfig] = useState({
    terminalId: "",
    username: "",
    password: "",
    confirmPassword: "",
    customerCode: "",
    status: "active",
  });

  const [paymentConfigErrorState, setPaymentConfigErrorState] = useState({
    terminalIdError: false,
    customerCodeError: false,
    usernameError: false,
    passwordError: false,
    confirmPasswordError: false,
  });

  const [addPaymentConfigError, setAddPaymentConfigError] = useState(0);
  const [addPaymentConfigErrorMsg, setAddPaymentConfigErrorMsg] = useState(-1);

  let alert = null;
  let merchantStatusState =
    props.merchantArray[props.merchantIndex + props.page * 10].status;

  let [paymentEditIndex, setPaymentEditIndex] = useState("");

  const [paymentConfigList, setPaymentConfigList] = useState([
    ...props.merchantArray[props.merchantIndex + props.page * 10]
      .paymentConfigurations,
  ]);

  const [loading, setLoading] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  /* This method is used to handle and validate payment configs form including 
  terminalId, customerCode, username, password, and confirm password. 
  */
  const inputHandler = (e) => {
    setPaymentConfig({ ...paymentConfig, [e.target.name]: e.target.value });
    setAddPaymentConfigError(null);
    setAddPaymentConfigErrorMsg("");

    if (e.target.value.length >= 0 && e.target.name === "terminalId") {
      setPaymentConfigErrorState({
        ...paymentConfigErrorState,
        terminalIdError: false,
      });
    }

    if (e.target.value.length >= 0 && e.target.name === "customerCode") {
      setPaymentConfigErrorState({
        ...paymentConfigErrorState,
        customerCodeError: false,
      });
    }

    if (e.target.value.length >= 0 && e.target.name === "username") {
      setPaymentConfigErrorState({
        ...paymentConfigErrorState,
        usernameError: false,
      });
    }

    if (e.target.value.length > 0 && e.target.name === "password") {
      setPaymentConfigErrorState({
        ...paymentConfigErrorState,
        passwordError: false,
        confirmPasswordError: false,
      });
    }

    if (e.target.value.length >= 0 && e.target.name === "confirmPassword") {
      setPaymentConfigErrorState({
        ...paymentConfigErrorState,
        confirmPasswordError: false,
      });
    }
  };

  /* This method is used to handle adding payment config
  It handles duplicatedTerminalId 
  */
  const plusHandler = (e) => {
    setAddPaymentConfigError(null);

    if (isPaymentConfigValid()) {
      let merchantId =
        props.merchantArray[props.merchantIndex + props.page * 10].id;

      let placeholderObj = [...paymentConfigList];
      let duplicatedTerminalId = placeholderObj.some((pymtConfigObj) => {
        return pymtConfigObj.terminalId === paymentConfig.terminalId;
      });

      const accessToken = oktaAuth.getAccessToken();

      if (duplicatedTerminalId) {
        setAddPaymentConfigError("error");
        setAddPaymentConfigErrorMsg(
          `Payment configuration with terminal id '${paymentConfig.terminalId}' already exists for merchant.`
        );
      } else {
        setLoading(true);
        setOpenBackdrop(!openBackdrop);
        fetch(
          `${getDomain()}tenants/${
            props.tenantName
          }/configTypes/EMV/merchants/${merchantId}/paymentConfigs`,
          {
            method: "post",
            body: JSON.stringify({
              terminalId: paymentConfig.terminalId,
              username: paymentConfig.username,
              password: paymentConfig.password,
              customerCode: paymentConfig.customerCode,
            }),
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
          .then((response) => {
            return response.json();
          })

          .then((object) => {
            if (object.status === "Success") {
              placeholderObj.unshift({ ...paymentConfig, edit: false });
              setPaymentConfigList([...placeholderObj]);
              setAddPaymentConfigError("success");
              setAddPaymentConfigErrorMsg(
                "A new Payment Configuration is added"
              );

              setPaymentConfig({
                terminalId: "",
                username: "",
                password: "",
                confirmPassword: "",
                customerCode: "",
                status: "active",
              });

              props.addPaymentConfig(
                placeholderObj,
                props.merchantIndex + props.page * 10
              );
              setLoading(false);
              setOpenBackdrop(false);
            } else {
              return Promise.reject(object.error);
            }
          })
          .catch((error) => {
            setAddPaymentConfigError("error");
            setAddPaymentConfigErrorMsg(error.errorMessage);
          });
      }
    }
  };

  /* This method is used to handle the validation for field form when adding
  payment configuration. It will return true, if all fields are filled correctly
  ,otherwise, a false will be returned.
  */
  const isPaymentConfigValid = (e) => {
    let output = false;

    if (
      FormValidation.terminalIdValid(paymentConfig.terminalId) &&
      FormValidation.customerCodeValid(paymentConfig.customerCode) &&
      FormValidation.usernameValid(paymentConfig.username) &&
      paymentConfig.password.length !== 0 &&
      paymentConfig.confirmPassword === paymentConfig.password
    ) {
      setPaymentConfigErrorState({
        ...paymentConfigErrorState,
        confirmPasswordError: false,
      });
      output = true;
    } else {
      setPaymentConfigErrorState({
        ...paymentConfigErrorState,
        terminalIdError: !FormValidation.terminalIdValid(
          paymentConfig.terminalId
        ),
        customerCodeError: !FormValidation.customerCodeValid(
          paymentConfig.customerCode
        ),
        usernameError: !FormValidation.usernameValid(paymentConfig.username),
        passwordError: paymentConfig.password.length === 0,
        confirmPasswordError:
          paymentConfig.confirmPassword !== paymentConfig.password,
      });
    }
    return output;
  };

  /* This method is used to update the edit attribute when a user clicks close modal, 
  The edit is set to false when use clicks on close.
  */
  const handleClose = () => {
    let placeholderArray = [...paymentConfigList];

    let temp = placeholderArray.map((item, index) => {
      if (index === paymentEditIndex) {
        item.edit = false;
        return item;
      }
      return item
    });
    setPaymentConfigList([...temp]);
    props.closeConfigModalHandler(false);
  };

  /* This method is used to set payment status attribute to active or disabled 
  when user switches it.  */
  const processPaymentStatus = (paymentStatus, paymentIndex) => {
    let placeholderArray = [...paymentConfigList];
    let newArray = placeholderArray.map((item, index) => {
      if (index === paymentIndex) {
        item.status = paymentStatus === true ? "active" : "disabled";
      }
      return item;
    });
    setPaymentConfigList([...newArray]);
  };

  /* This method is used to handle edit payment config
  If the edit mode is on, we set editIndex to not empty to evaluate for plus sign
  in addition, set attribute edit to true
  */
  const handlePaymentEdit = (paymentIndex, editStatus, updatedPaymentData) => {
    let placeholderArray = [...paymentConfigList];

    let temp = placeholderArray.map((item, index) => {
      if (index === paymentIndex && editStatus === "close") {
        item.edit = false;
        item.terminalId = updatedPaymentData.terminalId;
        item.customerCode = updatedPaymentData.customerCode;
        item.username = updatedPaymentData.username;
        item.password = "******";
        item.confirmPassword = "******";
        setPaymentEditIndex("");
        setAddPaymentConfigError("");
      } else if (index === paymentIndex && editStatus === "on") {
        item.edit = true;
        setPaymentEditIndex(paymentIndex);
        setAddPaymentConfigError("");
      }
      return item;
    });
    setPaymentConfigList([...temp]);
  };

  /* This method is used to handle the error message*/
  let handleEditMessage = (editFlagMessage, errorMessage = null) => {
    if (errorMessage !== null) {
      setAddPaymentConfigError("error");
      setAddPaymentConfigErrorMsg(errorMessage);
    } else if (editFlagMessage === "editInProgress") {
      setAddPaymentConfigError("error");
      setAddPaymentConfigErrorMsg(
        "Editing is in progress, please save your update"
      );
    } else if (editFlagMessage === "success") {
      setAddPaymentConfigError("success");
      setAddPaymentConfigErrorMsg("Payment Configuration is updated");
    } else if (editFlagMessage === "noEdit") {
      setAddPaymentConfigErrorMsg(errorMessage);
    }
  };

  if (addPaymentConfigError === "success") {
    alert = (
      <Box
        className={`${classes.message} paymentConfigurationSuccessMessage`}
        color="green"
      >
        {addPaymentConfigErrorMsg}
      </Box>
    );
  } else if (addPaymentConfigError === "error") {
    alert = (
      <Box
        className={`${classes.message} paymentConfigurationErrorMessage`}
        color="red"
      >
        {addPaymentConfigErrorMsg}
      </Box>
    );
  }

  return (
    <Modal
      className={classes.modal}
      open={true}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      id="paymentConfigurationModal"
    >
      <Box className={`${classes.paper} paymentConfigurationModal`}>
        <Box height="3%" color="#FFF" padding={1} bgcolor="primary.main">
          Add Payment Config
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          height="80%"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            height="25%"
            margin={1}
            border={1}
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={5}
            >
              <Grid item>
                <TextField
                  disabled={merchantStatusState === "disabled" ? true : false}
                  required
                  id="terminalIdInput"
                  size="small"
                  name="terminalId"
                  label="Terminal Id"
                  variant="outlined"
                  className={classes.inputStyle}
                  value={paymentConfig.terminalId}
                  onChange={inputHandler}
                  error={paymentConfigErrorState.terminalIdError}
                  helperText={WarningError.terminalIdError(
                    paymentConfigErrorState.terminalIdError
                  )}
                />
              </Grid>
              <Grid item>
                <TextField
                  disabled={merchantStatusState === "disabled" ? true : false}
                  required
                  size="small"
                  id="customerCodeInput"
                  name="customerCode"
                  label="Customer Code"
                  variant="outlined"
                  className={classes.inputStyle}
                  value={paymentConfig.customerCode}
                  onChange={inputHandler}
                  error={paymentConfigErrorState.customerCodeError}
                  helperText={WarningError.customerCodeError(
                    paymentConfigErrorState.customerCodeError
                  )}
                />
              </Grid>
              <Grid item>
                <TextField
                  disabled={merchantStatusState === "disabled" ? true : false}
                  required
                  size="small"
                  name="username"
                  id="usernameInput"
                  label="Username"
                  variant="outlined"
                  className={classes.inputStyle}
                  value={paymentConfig.username}
                  onChange={inputHandler}
                  error={paymentConfigErrorState.usernameError}
                  helperText={WarningError.usernameError(
                    paymentConfigErrorState.usernameError
                  )}
                />
              </Grid>
              <Grid item>
                <TextField
                  disabled={merchantStatusState === "disabled" ? true : false}
                  required
                  type="password"
                  size="small"
                  id="passwordInput"
                  name="password"
                  label="Password"
                  variant="outlined"
                  className={classes.inputStyle}
                  value={paymentConfig.password}
                  onChange={inputHandler}
                  error={paymentConfigErrorState.passwordError}
                  helperText={WarningError.passwordError(
                    paymentConfigErrorState.passwordError
                  )}
                />
              </Grid>
              <Grid item>
                <TextField
                  disabled={merchantStatusState === "disabled" ? true : false}
                  required
                  type="password"
                  size="small"
                  name="confirmPassword"
                  id="confirmPasswordInput"
                  label="Confirm Password"
                  variant="outlined"
                  className={classes.inputStyle}
                  value={paymentConfig.confirmPassword}
                  onChange={inputHandler}
                  error={paymentConfigErrorState.confirmPasswordError}
                  helperText={WarningError.confirmPasswordError(
                    paymentConfigErrorState.confirmPasswordError
                  )}
                />
              </Grid>
              <Grid item>
                <Button
                  type="button"
                  size="small"
                  onClick={plusHandler}
                  disabled={
                    merchantStatusState === "disabled" ||
                    paymentEditIndex !== ""
                      ? true
                      : false
                  }
                  className={`addButton`}
                  style={{ marginLeft: "10px", fontSize: "28px" }}
                >
                  <i class="fas fa-plus"></i>
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Divider orientation="horizontal" />
          <Box
            style={{
              marginLeft: "30px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {paymentConfigList.length} payment config
          </Box>
          <Box
            mx={1}
            display="flex"
            height="85%"
            justifyContent="space-between"
            flexDirection="column"
          >
            {paymentConfigList.length > 0 ? (
              <PaymentConfigtable
                paymentConfigList={paymentConfigList}
                tenantName={props.tenantName}
                page={props.page}
                merchantIndex={props.merchantIndex}
                merchantArray={props.merchantArray}
                merchantStatus={merchantStatusState}
                handlePaymentStatus={processPaymentStatus}
                handlePaymentEdit={handlePaymentEdit}
                handleEditMessage={handleEditMessage}
              />
            ) : (
              <Box />
            )}
          </Box>
        </Box>
        {loading && (
          <Backdrop className={classes.backdrop} open={openBackdrop}>
            <CircularProgress size={36} className={classes.buttonProgress} />
          </Backdrop>
        )}
        <Box ml={3} mt={10} style={{ display: "block", position: "relative" }}>
          {alert}
          <Box>
            <Button
              variant="contained"
              color="Primary"
              onClick={handleClose}
              className={`close`}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalPaymentConfig;
