let FormValidation = {
    tenantNameValid: input => /^[0-9a-zA-Z]+$/.test(input),
    groupIdValid: input => /^\d+$/.test(input),
    merchantIdValid: input => /^[0-9a-zA-Z-]+$/.test(input),
    externalIdValid: input => /^\d+$/.test(input),
    existingExternalIdValid: input => /^\d+$/.test(input),
    terminalIdValid: input => /^\d+$/.test(input),
    customerCodeValid: input => /^[0-9a-zA-Z]+$/.test(input),
    usernameValid: input => /^[0-9a-zA-Z]+$/.test(input),
}

export default FormValidation;