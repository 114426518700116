import { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import WarningError from "./WarningError";
import FormValidation from "./FormValidation";
import { getDomain } from "./Domain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "30%",
    outline: "none",

    "@media (max-width: 1600px)": {
      height: "42%",
    },

    "@media (max-width: 1440px)": {
      height: "42%",
    },
  },
  formStyle: {
    marginLeft: "30px",
    marginTop: "10px",
  },
  tenantNameInput: {
    width: "auto",
    height: "55px",
  },
  tenantDescriptionInput: {
    width: "90%",
    height: "70px",
  },
  saveAddTenantBtn: {
    marginRight: "1rem",
    width: "5rem",
  },
  cancelAddTenantBtn: {
    width: "5rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
}));

const ModalEditTenant = (props) => {
  const { oktaAuth } = useOktaAuth();
  const classes = useStyles();

  const [tenantObj, setTenantObj] = useState({
    tenantName: props.updatedTenant.name,
    tenantDescription: props.updatedTenant.description,
  });

  let alert = null;

  const [addTenantError, setAddTenantError] = useState("");
  const [addTenantErrorMsg, setAddTenantErrorMsg] = useState("");
  const [formErrorState, setFormErrorState] = useState({
    tenantNameError: false,
    tenantDescriptionError: false,
  });
  const [loading, setLoading] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleClose = () => {
    props.closeEditModalHandler();
    setAddTenantError("");
    setAddTenantErrorMsg("");
  };

  const textFieldHandler = (e) => {
    setTenantObj({ ...tenantObj, [e.target.name]: e.target.value });
    setAddTenantError("");
    setAddTenantErrorMsg("");
    setFormErrorState({ ...formErrorState, tenantNameError: false });
  };

  const isTenantNameValid = (e) => {
    let output = true;

    if (!FormValidation.tenantNameValid(tenantObj.tenantName)) {
      output = false;
      setFormErrorState({ ...formErrorState, tenantNameError: true });
    }
    return output;
  };

  const saveEditTenantHandler = (e) => {
    const accessToken = oktaAuth.getAccessToken();
    let duplicatedOutput = props.tenantArray.some((obj) => {
      return (
        obj.name === tenantObj.tenantName &&
        obj.name !== props.updatedTenant.name
      );
    });

    let duplicatedTenantItem = props.tenantArray.some((obj) => {
      return (
        obj.name === tenantObj.tenantName &&
        obj.name === props.updatedTenant.name &&
        obj.description === tenantObj.tenantDescription
      );
    });

    if (duplicatedOutput) {
      setAddTenantError("error");
      setAddTenantErrorMsg(
        `Tenant with tenant name '${tenantObj.tenantName}' already exists.`
      );
    } else if (duplicatedTenantItem) {
      props.closeEditModalHandler();
    } else if (isTenantNameValid()) {
      setLoading(true);
      setOpenBackdrop(!openBackdrop);
      fetch(`${getDomain()}tenants/${props.updatedTenant.name}`, {
        method: "put",
        body: JSON.stringify({
          name: tenantObj.tenantName,
          description: tenantObj.tenantDescription,
        }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((object) => {
          if (object.status === "Success") {
            setLoading(false);
            setOpenBackdrop(false);
            setAddTenantError("success");
            setAddTenantErrorMsg(`Tenant is updated`);
            props.editTenantProcessor(props.tenantIndex, tenantObj);
          } else {
            return Promise.reject(object.error);
          }
        })
        .catch((error) => {});
    }
  };

  if (addTenantError === "success") {
    alert = (
      <Box className={`${classes.message} tenantSuccessMessage`} color="green">
        {addTenantErrorMsg}
      </Box>
    );
  } else if (addTenantError === "error") {
    alert = (
      <Box className={`${classes.message} tenantErrorMessage`} color="red">
        {addTenantErrorMsg}
      </Box>
    );
  }

  return (
    <Modal
      className={classes.modal}
      open={props.openEditTenantModal}
      onClose={props.closeEditModalHandler}
      BackdropComponent={Backdrop}
    >
      <section className={`${classes.paper} addTenantModal`}>
        <Box height="5%" color="#FFF" p={1} bgcolor="primary.main">
          Edit Tenant
        </Box>
        <form className={`${classes.formStyle}`}>
          <TextField
            required
            size="small"
            name="tenantName"
            label="Tenant Name"
            value={tenantObj.tenantName}
            variant="outlined"
            className={`${classes.tenantNameInput} tenantName`}
            id="tenantName"
            onChange={textFieldHandler}
            error={formErrorState.tenantNameError}
            helperText={WarningError.tenantNameError(
              formErrorState.tenantNameError
            )}
            inputProps={{ maxLength: 10 }}
          />
          <br />
          <br />
          <TextField
            required
            rows={6}
            multiline={true}
            inputProps={{ maxLength: 255 }}
            label="Tenant Description"
            placeholder="Tenant Description up to 256 characters"
            name="tenantDescription"
            className={`${classes.tenantDescriptionInput} tenantDescription`}
            value={tenantObj.tenantDescription}
            onChange={textFieldHandler}
            variant="outlined"
          />
        </form>
        <Box
          ml="2rem"
          mr="3.25rem"
          mt="5rem"
          mb="1rem"
          height="5rem"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box></Box>
          {alert}
          <Box>
            {loading && (
              <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress
                  size={36}
                  className={classes.buttonProgress}
                />
              </Backdrop>
            )}
            <Button
              variant="contained"
              color="Primary"
              className={`${classes.saveAddTenantBtn} save`}
              onClick={saveEditTenantHandler}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="Cancel"
              className={`${classes.cancelAddTenantBtn} close`}
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </Box>
      </section>
    </Modal>
  );
};

export default ModalEditTenant;
