import { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import WarningError from "./WarningError";
import FormValidation from "./FormValidation";
import { getDomain } from "./Domain";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  configureEmvModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "30%",
    height: "40%",
    outline: "none",
  },
  formStyle: {
    marginLeft: "30px",
    marginTop: "20px",
  },
  groupIdInputWidth: {
    width: "90%",
  },
  configureBtn: {
    marginLeft: "40px",
    fontSize: 14,
  },
  emvFont: {
    fontSize: 20,
  },
  saveBtn: {
    marginRight: "1rem",
    width: "5rem",
  },
}));

const ModalConfigureEmv = (props) => {
  const { oktaAuth } = useOktaAuth();
  const classes = useStyles();
  let [groupId, setGroupId] = useState("");
  let [errorGroupIdObj, setErrorGroupIdObj] = useState({
    groupIdError: false,
  });

  const [addConfigError, setAddConfigError] = useState("");
  const [addConfigErrorMsg, setAddConfigErrorMsg] = useState("");

  const [groupIdFlag, setGroupIdFlag] = useState(false);
  const [loading, setLoading] = useState(false);

  let alert = null;

  /* This method is used to close the modal. 
  Whenever this method is called, it will check if groupIdFlag is true, 
  if it is, emvHandler at parent component will be called,
  this will cause refresh to retrieve all data.
  Otherwise, it will close the modal as normal
  */
  const handleClose = () => {
    if (groupIdFlag === true) {
      props.emvHandler(true);
    } else {
      props.handleClose(false);
      setGroupId("");
      setAddConfigError("");
      setErrorGroupIdObj({ groupIdError: false });
    }
  };

  /* This method is used to set group id in Tenant View.
  If a groupId is created, set groupIdFlag to true to add the logic to 
  handle emv. 
  */
  const saveGroupId = (e) => {
    if (isGroupIdValid()) {
      const accessToken = oktaAuth.getAccessToken();
      setLoading(true);
      fetch(`${getDomain()}tenants/${props.tenantName}/configTypes`, {
        method: "post",
        body: JSON.stringify({
          configType: "EMV",
          groupId,
        }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((object) => {
          setLoading(false);
          if (object.status === "Success") {
            setGroupIdFlag(true);
            setGroupId("");
            setAddConfigError("success");
          } else {
            return Promise.reject(object.error);
          }
        })
        .catch((error) => {
          setAddConfigError("error");
          setAddConfigErrorMsg(error.errorMessage);
        });
    }
  };

  /* This method is used to support validation for the group id field when user clicks save button*/
  const isGroupIdValid = (e) => {
    let output = false;
    if (FormValidation.groupIdValid(groupId)) {
      setErrorGroupIdObj({ groupIdError: false });
      output = true;
    } else {
      setErrorGroupIdObj({ groupIdError: true });
    }
    return output;
  };

  /* This method is used to validate the group id field */
  const groupIdhandler = (e) => {
    setGroupId(e.target.value);
    setAddConfigError("");
    setAddConfigErrorMsg("");
    if (
      e.target.value.length > 0 &&
      !FormValidation.groupIdValid(e.target.value)
    ) {
      setErrorGroupIdObj({ groupIdError: true });
    } else {
      setErrorGroupIdObj({ groupIdError: false });
    }
  };

  if (addConfigError === "success") {
    alert = (
      <Box className={`emvSuccessMessage`} mb="1rem" color="green">
        A new EMV is added
      </Box>
    );
  } else if (addConfigError === "error") {
    alert = (
      <Box className={`emvErrorMessage`} color="red">
        {addConfigErrorMsg}
      </Box>
    );
  } else {
    alert = <Box>{addConfigErrorMsg}</Box>;
  }

  return (
    <Modal
      className={`${classes.configureEmvModal} configureEmv`}
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Box className={`${classes.paper} emvPaper`}>
        <Box height="6%" bgcolor="#2d42b9" color="#FFF" py={1} pl={1}>
          Configure EMV
        </Box>
        <Box
          height="85%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <form className={classes.formStyle}>
            <TextField
              required
              name="groupId"
              label="Group Id"
              variant="outlined"
              className={`${classes.groupIdInputWidth} groupId`}
              onChange={groupIdhandler}
              id="groupId"
              value={groupId}
              disabled={groupIdFlag}
              inputProps={{ maxLength: 10 }}
              size="small"
              error={errorGroupIdObj.groupIdError}
              helperText={WarningError.groupIdError(
                errorGroupIdObj.groupIdError
              )}
            />
          </form>
          <Box ml="2rem" mt="2rem" mb="1rem">
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            {alert}
            <Button
              variant="contained"
              color="Primary"
              className={`${classes.saveBtn} save`}
              onClick={saveGroupId}
              disabled={groupIdFlag}
            >
              Add
            </Button>
            <Button
              variant="outlined"
              color="Cancel"
              className={`close`}
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalConfigureEmv;
