
const domains = {
  "local": "http://localhost:3000/",
  "dev": "https://api.dev.paymentconfig.us.idemia.io/dev/",
  "test": "https://api.test.paymentconfig.us.idemia.io/test/",
}

export function getDomain() {
  if (process.env.REACT_APP_ENVIRONMENT){
    let domain = process.env.REACT_APP_ENVIRONMENT;
    switch (domain){
      case "local": return domains.local;
      case "dev": return domains.dev;
      case "test": return domains.test;
      default: return domain;
    } 
  } else {
    throw "REACT_APP_ENVIRONMENT not set."
  }
}