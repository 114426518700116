import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Emv from "./Emv";
import NonEmv from "./NonEmv";
import { Link } from "react-router-dom";
import { getDomain } from "./Domain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tab: {
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  tabFont: {
    fontSize: 16,
  },
  btnStyle: {
    fontSize: 14,
    width: "5rem",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const TenantView = (props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  let [tenantDetail, setTenantDetail] = useState({});
  let [emvFlag, setEmvFlag] = useState("");
  let [emvFlagRequest, setEmvFlagRequest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  /* This method is called whenever a emvFlagRequest is updated*/
  useEffect(() => {
    if (authState.isAuthenticated) {
      setLoading(true);
      setOpenBackdrop(!openBackdrop);
      const accessToken = oktaAuth.getAccessToken();
      fetch(`${getDomain()}tenants/${props.match.params.name}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((object) => {
          setTenantDetail(object);
          setLoading(false);
          setOpenBackdrop(false);
          if (object.configTypes.length > 0) {
            object.configTypes.forEach((item) => {
              if (
                item.type === "EMV" &&
                item.merchants.length > 0 &&
                item.groupId
              ) {
                setEmvFlag("emv");
              } else if (item.type === "EMV" && item.merchants.length === 0) {
                setEmvFlag("no-merchant");
              }
            });
          }
        });
    }
  }, [authState, emvFlagRequest]);

  /* This method is used to set the emvFlag whenever a user clicks on emv, 
    We set emvFlagRequest, and whenever its value is changed, the refresh is run.
    */
  let emvHandler = (flag) => {
    setEmvFlag(flag);
    setEmvFlagRequest(flag);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box mx="2rem">
      {loading && (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress size={36} className={classes.buttonProgress} />
        </Backdrop>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        mb="1rem"
        height="2rem"
        mt="7rem"
      >
        <Typography variant="h5">
          <strong>Tenant Name: </strong>
          {props.match.params.name}
        </Typography>
        <Button
          component={Link}
          to={`/`}
          variant="contained"
          color="primary"
          type="button"
          onClick={props.backButtonClick}
          className={`${classes.btnStyle} backButton`}
        >
          Back
        </Button>
      </Box>
      <Typography variant="h5">
        <strong>Tenant Description: </strong>
        {tenantDetail.description}
      </Typography>
      <Box width="35%" mt="1.25rem">
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              label="EMV"
              {...a11yProps(0)}
              className={`${classes.tabFont} emvTab`}
            />
            <Tab
              label="Non-EMV"
              {...a11yProps(1)}
              className={`${classes.tabFont} non-emvTab`}
            />
          </Tabs>
        </AppBar>
      </Box>
      <Divider />
      <TabPanel
        value={value}
        index={0}
        dir={theme.direction}
        className={classes.tab}
        style={{ height: "100vh" }}
      >
        <Emv
          tenantDetail={tenantDetail}
          emvFlag={emvFlag}
          emvHandler={emvHandler}
        />
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        dir={theme.direction}
        className={classes.tab}
        style={{ height: "100vh" }}
      >
        <NonEmv
          tenantDetail={tenantDetail}
          tenantName={props.match.params.name}
        />
      </TabPanel>
    </Box>
  );
};

export default TenantView;
