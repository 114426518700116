import { React, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Merchant from "./Merchant";
import ModalConfigureEmv from "./ModalConfigureEmv";

const useStyles = makeStyles((theme) => ({
  configureEmvModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "30%",
    height: "40%",
    outline: "none",
  },
  formStyle: {
    marginLeft: "30px",
    marginTop: "20px",
  },
  groupIdInputWidth: {
    width: "90%",
  },
  configureBtn: {
    marginLeft: "40px",
    fontSize: 14,
  },
  emvFont: {
    fontSize: 20,
  },
  saveBtn: {
    marginRight: "1rem",
    width: "5rem",
  },
}));

const Emv = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  let emvResult = null;

  let handleOpen = () => {
    setOpen(true);
  };

  /* open configure modal */
  let handleClose = () => {
    setOpen(false);
  };

  /* close configure modal */
  let emvHandler = (flag) => {
    props.emvHandler(flag);
  };

  /* Whenever the config type is existent, the emvFlag is true*/
  if (props.emvFlag === 'emv' || props.emvFlag === 'no-merchant') {
    emvResult = (
      <Merchant
        tenantDetailObject={props.tenantDetail.configTypes}
        tenantName={props.tenantDetail.name}
      />
    );
  } else {
    emvResult = (
      <Box className={classes.emvFont}>
        EMV is not configured
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          className={`${classes.configureBtn} configureEmvButton`}
        >
          Configure EMV
        </Button>
        <ModalConfigureEmv
          open={open}
          tenantName={props.tenantDetail.name}
          handleClose={handleClose}
          emvHandler={emvHandler}
        />
      </Box>
    );
  }
  return <Box mt="2rem">{emvResult}</Box>;
};

export default Emv;
