import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ModalExistingMerchant from "./ModalExistingMerchant";
import ModalPaymentConfig from "./ModalPaymentConfig";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TablePagination from "@material-ui/core/TablePagination";
import { getDomain } from "./Domain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  tenantNameCell: {
    fontWeight: "bold",
  },
  tableHeader: {
    backgroundColor: "#d3d3d3",
  },
  columnCell: {
    width: "20%",
  },
  tableCellFont: {
    fontSize: 16,
  },
  editBtn: {
    width: "30%",
    marginRight: "30px",
    marginBottom: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
}));

const MerchantTable = (props) => {
  const { oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [merchantIndex, setMerchantIndex] = useState(0);
  const [openPaymentConfigModal, setOpenPaymentConfigModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  /* This method is used to open a modal which allows user to add an external Id 
  to an existing merchant where its index point to
  */
  const openExistingMerchantModal = (index) => {
    setMerchantIndex(index);
    setOpenModal(true);
  };

  /* This will close the adding external Id to a modal */
  const closeExistingMerchantModal = () => {
    setOpenModal(false);
  };

  /* This method is used to handle the merchant status when 
  a user switches from active to disabled, and vice versa. 
  Whenever you call e.target.checked, the switch will be triggered.
  */
  const handleChange = (index, e) => {
    const accessToken = oktaAuth.getAccessToken();
    setLoading(true);
    setOpenBackdrop(!openBackdrop);
    fetch(
      `${getDomain()}tenants/${props.tenantName}/configTypes/EMV/merchants/${
        props.merchantArray[props.page * 10 + index].id
      }`,
      {
        method: "put",
        body: JSON.stringify({
          status: e.target.checked ? "active" : "disabled",
        }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((object) => {
        if (object.status === "Success") {
          props.updateMerchantStatus(
            !e.target.checked,
            props.page * 10 + index
          );
          setLoading(false);
          setOpenBackdrop(false);
        } else {
          return Promise.reject(object.error);
        }
      })
      .catch((error) => {});
  };
  /* This method will save a paymentConfig list into a merchant where its index maps to */
  const addPaymentConfig = (paymentConfigList, merchantIndex) => {
    props.saveListPaymentConfig(paymentConfigList, merchantIndex);
  };

  /* This method will open configuration modal and set the merchant index*/
  const openConfigModalHandler = (index) => {
    setMerchantIndex(index);
    setOpenPaymentConfigModal(true);
  };

  /* This method will olose the configuration modal */
  const closeConfigModalHandler = () => {
    setOpenPaymentConfigModal(false);
  };

  /* Handle pagination*/
  const handleChangePage = (event, newPage) => {
    props.handleChangePage(newPage);
  };

  return (
    <Paper>
      <TableContainer component={Paper}>
        {loading && (
          <Backdrop className={classes.backdrop} open={openBackdrop}>
            <CircularProgress size={36} className={classes.buttonProgress} />
          </Backdrop>
        )}
        <Table className={`${classes.table} listOfMerchant`}>
          <colgroup>
            <col className={classes.columnCell} />
            <col className={classes.columnCell} />
            <col className={classes.columnCell} />
            <col className={classes.columnCell} />
            <col className={classes.columnCell} />
          </colgroup>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={classes.tableCell}>
                Merchant Status
              </TableCell>
              <TableCell className={classes.tableCell}>Merchant Id</TableCell>
              <TableCell className={classes.tableCell}>
                Idemia Location Id
              </TableCell>
              <TableCell className={classes.tableCell}>
                Payment Configuration
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.merchantArray
              .slice(
                props.page * props.rowsPerPage,
                props.page * props.rowsPerPage + props.rowsPerPage
              )
              .map((row, index) => (
                <TableRow key={row.id} className={`merchantRow`}>
                  <TableCell className={`merchantStatusToggle`}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={row.status === "active" ? true : false}
                          onChange={(e) => handleChange(index, e)}
                          value={
                            row.status === "active" ? "Active" : "Inactive"
                          }
                          id={`merchantStatusToggle-${index}`}
                          color="primary"
                        />
                      }
                      label={row.status === "active" ? "Active" : "Inactive"}
                    />
                  </TableCell>

                  <TableCell className={`${classes.tableCellFont} merchantId`}>
                    <strong>{row.id}</strong>
                  </TableCell>
                  <TableCell className={`${classes.tableCellFont} externalId`}>
                    <Button
                      color="primary"
                      onClick={() => openExistingMerchantModal(index)}
                      className={`viewMerchantExternalId`}
                    >
                      {row.merchantExternalIds.length} Idemia Location Id
                    </Button>
                    {merchantIndex === index && openModal === true ? (
                      <ModalExistingMerchant
                        index={merchantIndex}
                        page={props.page}
                        tenantName={props.tenantName}
                        merchantArray={props.merchantArray}
                        openExistingMerchantModal={openExistingMerchantModal}
                        closeExistingMerchantModal={closeExistingMerchantModal}
                        saveListExternalId={props.saveListExternalId}
                        deleteListExternalId={props.deleteListExternalId}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell
                    className={`${classes.tableCellFont} paymentConfig`}
                  >
                    <Button
                      color="primary"
                      onClick={() => openConfigModalHandler(index)}
                      className={`viewPaymentConfiguration`}
                    >
                      {row.paymentConfigurations.length} Payment Configurations
                    </Button>
                    {openPaymentConfigModal === true &&
                    merchantIndex === index ? (
                      <ModalPaymentConfig
                        merchantIndex={merchantIndex}
                        tenantName={props.tenantName}
                        merchantArray={props.merchantArray}
                        openConfigModalHandler={openConfigModalHandler}
                        closeConfigModalHandler={closeConfigModalHandler}
                        addPaymentConfig={addPaymentConfig}
                        page={props.page}
                      />
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={`${classes.root} parentPagination`}
        nextIconButtonProps={{  'id': 'nextPage' }}
        backIconButtonProps={{  'id': 'previousPage' }}
        component="div"
        rowsPerPageOptions={[5]}
        count={props.merchantArray.length}
        rowsPerPage={10}
        page={props.page}
        onChangePage={handleChangePage}
        classes={{
          caption: `paginationText`
        }}
      />
    </Paper>
  );
};

export default MerchantTable;
