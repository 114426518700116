import { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import WarningError from "./WarningError";
import FormValidation from "./FormValidation";
import { getDomain } from "./Domain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "30%",
    height: "50%",
    outline: "none",
  },
  addMerchantFont: {
    fontSize: 16,
  },
  formStyle: {
    marginLeft: "30px",
    marginTop: "20px",
  },
  merchantId: {
    width: "90%",
    height: "3.5rem",
    marginBottom: "1rem",
  },
  searchMerchantId: {
    width: "17%",
  },
  externalId: {
    width: "90%",
    height: "3.5rem",
    marginBottom: "1rem",
  },
  password: {
    width: "90%",
    height: "3.5rem",
    marginBottom: "1rem",
  },
  confirmPassword: {
    width: "90%",
    height: "3.5rem",
    marginBottom: "1rem",
  },
  saveBtn: {
    marginRight: "1rem",
    width: "5rem",
  },
  cancelBtn: {
    marginRight: "1rem",
    width: "5rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
}));

const NonEmvModalEditMerchant = (props) => {
  const { oktaAuth } = useOktaAuth();
  const classes = useStyles();

  let [nonEmvMerchant, setNonEmvMerchant] = useState({
    merchantId: props.updatedMerchant.id,
    externalId: props.updatedMerchant.merchantExternalIds[0],
    password: "",
    confirmPassword: "",
  });

  const [nonEmvMerchantErrorState, setNonEmvMerchantErrorState] = useState({
    merchantIdError: false,
    externalIdError: false,
    passwordError: false,
    confirmPasswordError: false,
  });

  const [nonEmvMerchantStatus, setNonEmvMerchantStatus] = useState({
    merchantId: false,
    externalId: false,
    password: false,
    confirmPassword: false,
  });

  const [editMerchantError, setEditMerchantError] = useState("");
  const [editMerchantErrorMsg, setEditMerchantErrorMsg] = useState("");

  const [fieldFlag, setFieldFlag] = useState({
    merchantId: false,
    externalId: false,
    password: false,
    confirmPassword: false,
    editCheck: false,
  });
  const [loading, setLoading] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  let alert = null;

  const handleClose = () => {
    props.closeEditModalHandler();
  };

  const inputHandler = (e) => {
    setNonEmvMerchant({ ...nonEmvMerchant, [e.target.name]: e.target.value });
    setEditMerchantError('');
    setEditMerchantErrorMsg('');

    if (e.target.value.length >= 0 && e.target.name === "merchantId") {
      setFieldFlag({ ...fieldFlag, merchantId: true, editCheck: true });
      setNonEmvMerchantStatus({ ...nonEmvMerchantStatus, merchantId: true });
      setNonEmvMerchantErrorState({
        ...nonEmvMerchantErrorState,
        merchantIdError: false,
      });
    }
    if (e.target.value.length >= 0 && e.target.name === "externalId") {
      setFieldFlag({ ...fieldFlag, externalId: true, editCheck: true });
      setNonEmvMerchantStatus({ ...nonEmvMerchantStatus, externalId: true });
      setNonEmvMerchantErrorState({
        ...nonEmvMerchantErrorState,
        externalIdError: false,
      });
    }
    if (e.target.value.length > 0 && e.target.name === "confirmPassword") {
      setFieldFlag({ ...fieldFlag, confirmPassword: true, editCheck: true });
      setNonEmvMerchantStatus({ ...nonEmvMerchantStatus, confirmPassword: true });
      setNonEmvMerchantErrorState({
        ...nonEmvMerchantErrorState,
        confirmPasswordError: false,
        passwordError: false
      });
    }

    if (e.target.value.length > 0 && e.target.name === "password") {
      setFieldFlag({
        ...nonEmvMerchantStatus,
        password: true,
        editCheck: true,
      });
      setNonEmvMerchantStatus({ ...nonEmvMerchantStatus, password: true });
      setNonEmvMerchant({ ...nonEmvMerchant, [e.target.name]: e.target.value });
      setNonEmvMerchantErrorState({
        ...nonEmvMerchantErrorState,
        passwordError: false,
        confirmPasswordError: false,
      });
    } else if (e.target.value.length === 0 && e.target.name === "password") {
      setNonEmvMerchantStatus({ ...nonEmvMerchantStatus, password: false });
      setNonEmvMerchantErrorState({
        ...nonEmvMerchantErrorState,
        passwordError: false,
        confirmPasswordError: false,
      });
    }
  };

  let saveEditMerchantHandler = (e) => {
    const accessToken = oktaAuth.getAccessToken();
    let result = {};

    let duplicatedMerchant = props.nonEmvMerchantArray.some((obj) => {
      return (
        obj.id === nonEmvMerchant.merchantId && 
        obj.id !== props.updatedMerchant.id
      );
    });

    let duplicatedExternalId = props.nonEmvMerchantArray.some((obj) => {
      return (
        obj.merchantExternalIds[0] === nonEmvMerchant.externalId &&
        obj.merchantExternalIds[0] !== props.updatedMerchant.merchantExternalIds[0]
      );
    });

    if (nonEmvMerchantStatus.merchantId) {
      result.merchantId = nonEmvMerchant.merchantId;
    }
    if (
      nonEmvMerchantStatus.externalId &&
      nonEmvMerchant.externalId !== props.updatedMerchant.merchantExternalIds[0]
    ) {
      result.externalId = nonEmvMerchant.externalId;
    }

    if (nonEmvMerchantStatus.password) {
      result.password = nonEmvMerchant.password;
    }

    if (duplicatedMerchant && nonEmvMerchantStatus.merchantId) {
      setEditMerchantError("error");
      setEditMerchantErrorMsg(
        `Merchant with merchant id '${nonEmvMerchant.merchantId}' already exists for configuration type.`
      );
    } else if (duplicatedExternalId && nonEmvMerchantStatus.externalId) {
      setEditMerchantError("error");
      setEditMerchantErrorMsg(
        `External Id '${nonEmvMerchant.externalId}' already exists for a merchant in this configuration type.`
      );
    } else if (!fieldFlag.editCheck) {
      handleClose();
    } else if (
      isMerchantValid() &&
      nonEmvMerchant.merchantId  === props.updatedMerchant.id &&
      nonEmvMerchant.externalId  === props.updatedMerchant.merchantExternalIds[0]  && 
      !nonEmvMerchantStatus.password
    ) {
      handleClose();
      setFieldFlag({ ...fieldFlag, editCheck: false });
    } else if (isMerchantValid()) {
      setLoading(true);
      setOpenBackdrop(!openBackdrop);
      fetch(
        `${getDomain()}tenants/${
          props.tenantName
        }/configTypes/NON-EMV/merchants/${props.updatedMerchant.id}`,
        {
          method: "put",
          body: JSON.stringify(result),
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((object) => {
          if (object.status === "Success") {
            setLoading(false);
            setOpenBackdrop(false);
            setEditMerchantError("success");
            setEditMerchantErrorMsg(`Merchant is updated`);
            props.editNonEmvMerchant(
              result,
              props.merchantIndex + props.page * 10
            );
          } else {
            return Promise.reject(object.error);
          }
        })
        .catch((error) => {});
    }
  };

  /* This method is used to handle the validation for field form when editing
  non emv merchant. It will return true, if all fields are filled correctly
  ,otherwise, a false will be returned.
  */
  const isMerchantValid = (e) => {
    let output = false;

    if (
      FormValidation.merchantIdValid(nonEmvMerchant.merchantId) &&
      FormValidation.externalIdValid(nonEmvMerchant.externalId) &&
      nonEmvMerchant.confirmPassword === nonEmvMerchant.password
    ) {
      setNonEmvMerchantErrorState({
        ...nonEmvMerchantErrorState,
        confirmPasswordError: false,
      });
      output = true;
    } else {
      setNonEmvMerchantErrorState({
        ...nonEmvMerchantErrorState,
        merchantIdError: !FormValidation.merchantIdValid(
          nonEmvMerchant.merchantId
        ),
        externalIdError: !FormValidation.externalIdValid(
          nonEmvMerchant.externalId
        ),
        confirmPasswordError:
          nonEmvMerchant.confirmPassword !== nonEmvMerchant.password,
      });
    }
    return output;
  };

  if (editMerchantError === "success") {
    alert = (
      <Box className={`${classes.message} tenantSuccessMessage`} color="green">
        {editMerchantErrorMsg}
      </Box>
    );
  } else if (editMerchantError === "error") {
    alert = (
      <Box className={`${classes.message} tenantErrorMessage`} color="red">
        {editMerchantErrorMsg}
      </Box>
    );
  }

  return (
    <Modal
      className={classes.modal}
      open={props.openEditMerchantModal}
      onClose={props.closeEditModalHandler}
      id="addNonMerchantId"
    >
      <Box className={`${classes.paper} addMerchantModal`}>
        <Box height="5%" color="#FFF" padding={1} bgcolor="primary.main">
          Edit Merchant
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="86%"
        >
          <form className={classes.formStyle}>
            <TextField
              required
              size="small"
              name="merchantId"
              label="Merchant Id"
              variant="outlined"
              onChange={inputHandler}
              id="merchantIdInput"
              value={nonEmvMerchant.merchantId}
              className={`${classes.merchantId} merchantIdInput`}
              error={nonEmvMerchantErrorState.merchantIdError}
              helperText={WarningError.merchantIdError(
                nonEmvMerchantErrorState.merchantIdError
              )}
            />
            <TextField
              required
              size="small"
              name="externalId"
              label="Idemia Location Id"
              variant="outlined"
              onChange={inputHandler}
              id="externalIdInput"
              value={nonEmvMerchant.externalId}
              className={`${classes.externalId} externalIdInput`}
              error={nonEmvMerchantErrorState.externalIdError}
              helperText={WarningError.externalIdError(
                nonEmvMerchantErrorState.externalIdError
              )}
            />
            <TextField
              required
              type="password"
              size="small"
              name="password"
              label="Password"
              placeholder="Leave empty if password is unchanged"
              variant="outlined"
              onChange={inputHandler}
              id="passwordInput"
              value={nonEmvMerchant.password}
              className={`${classes.password} passwordInput`}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              required
              type="password"
              size="small"
              name="confirmPassword"
              label="Confirm Password"
              placeholder="Leave empty if password is unchanged"
              variant="outlined"
              onChange={inputHandler}
              id="confirmPasswordInput"
              error={nonEmvMerchantErrorState.confirmPasswordError}
              helperText={WarningError.confirmPasswordError(
                nonEmvMerchantErrorState.confirmPasswordError
              )}
              InputLabelProps={{
                shrink: true,
              }}
              value={nonEmvMerchant.confirmPassword}
              className={`${classes.confirmPassword} confirmPasswordInput`}
            />
          </form>
          <Box ml={3.5}>
            {alert}
            <Box mt="1rem">
            {loading && (
              <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress
                  size={36}
                  className={classes.buttonProgress}
                />
              </Backdrop>
            )}
              <Button
                variant="contained"
                color="Primary"
                className={`${classes.saveBtn} save`}
                onClick={saveEditMerchantHandler}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="Cancel"
                className={`${classes.cancelBtn} close`}
                onClick={handleClose}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default NonEmvModalEditMerchant;
