import { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import WarningError from "./WarningError";
import FormValidation from "./FormValidation";
import { getDomain } from "./Domain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "30%",
    height: "40%",
    outline: "none",
  },
  addMerchantFont: {
    fontSize: 16,
  },
  formStyle: {
    marginLeft: "30px",
    marginTop: "20px",
  },
  merchantId: {
    width: "90%",
    height: "3.5rem",
    marginBottom: "1rem",
  },
  searchMerchantId: {
    width: "17%",
  },
  externalId: {
    width: "90%",
    height: "45px",
  },
  saveBtn: {
    marginRight: "1rem",
    width: "5rem",
  },
  cancelBtn: {
    marginRight: "1rem",
    width: "5rem",
  },

  formSearch: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ModalAddMerchant = (props) => {
  const { oktaAuth } = useOktaAuth();
  const classes = useStyles();
  let [merchantInputField, setMerchantInputField] = useState({
    merchantId: "",
    externalId: "",
  });

  let [merchantErrorState, setMerchantErrorState] = useState({
    merchantIdError: false,
    externalIdError: false,
  });

  const [addMerchantError, setAddMerchantError] = useState("");
  const [addMerchantErrorMsg, setAddMerchantErrorMsg] = useState("");
  const [saveStatus, setSaveStatus] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [loading, setLoading] = useState(false);
  let alert = null;

  /* This method is used when a user clicks on close button. 
  As long as a user clicks on close button, all the form is set to empty strings, 
  error states are also set to false, saveStatus is set to false to enable the button save
  the error msg is set back to empty string also.
  */
  const handleClose = () => {
    setMerchantInputField({ merchantId: "", externalId: "" });
    setMerchantErrorState({ merchantIdError: false, externalIdError: false });
    props.handleClose();
    setAddMerchantError("");
    setAddMerchantErrorMsg("");
    setSaveStatus(false);
  };

  /* This method is used to validate the merchantId and external Id when a user clicks save to submit the merchant form */
  const isMerchantValid = (e) => {
    let output = false;
    if (
      FormValidation.merchantIdValid(merchantInputField.merchantId) &&
      FormValidation.merchantIdValid(merchantInputField.externalId)
    ) {
      setMerchantErrorState({ merchantIdError: false, externalIdError: false });
      output = true;
    } else if (
      FormValidation.merchantIdValid(merchantInputField.merchantId) &&
      !FormValidation.merchantIdValid(merchantInputField.externalId)
    ) {
      setMerchantErrorState({ ...merchantErrorState, externalIdError: true });
    } else if (
      !FormValidation.merchantIdValid(merchantInputField.merchantId) &&
      FormValidation.merchantIdValid(merchantInputField.externalId)
    ) {
      setMerchantErrorState({ ...merchantErrorState, merchantIdError: true });
    } else {
      setMerchantErrorState({ merchantIdError: true, externalIdError: true });
    }
    return output;
  };

  /* This method is to handle change in the merchant form. 
    As long as a user clicks on merchant Id form,  
  saveStatus is set to false to enable the button save
  the error msg is set back to empty string also.
  */
  const merchantHandler = (e) => {
    setSaveStatus(false);
    setAddMerchantError("");
    setAddMerchantErrorMsg("");
    setMerchantInputField({
      ...merchantInputField,
      [e.target.name]: e.target.value,
    });
    if (
      e.target.value.length > 0 &&
      !FormValidation.merchantIdValid(e.target.value)
    ) {
      setMerchantErrorState({ ...merchantErrorState, merchantIdError: true });
    } else {
      setMerchantErrorState({
        ...merchantErrorState,
        merchantIdError: false,
      });
    }
  };

  /* This method is to handle change in the external Id form. 
    As long as a user clicks on merchant Id form,  
  saveStatus is set to false to enable the button save
  the error msg is set back to empty string also.
  */

  const externalHandler = (e) => {
    setSaveStatus(false);
    setAddMerchantError("");
    setAddMerchantErrorMsg("");
    setMerchantInputField({
      ...merchantInputField,
      [e.target.name]: e.target.value,
    });
    if (
      e.target.value.length > 0 &&
      !FormValidation.externalIdValid(e.target.value)
    ) {
      setMerchantErrorState({ ...merchantErrorState, externalIdError: true });
    } else {
      setMerchantErrorState({
        ...merchantErrorState,
        externalIdError: false,
      });
    }
  };

  /* This  method is triggered when a user clicks on save to submit the merchant form.
  It handled the duplicated merchant or external Id
  */
  const saveMerchant = (e) => {
    let duplicatedMerchant = props.merchantArray.some((obj) => {
      return obj.id === merchantInputField.merchantId;
    });

    let duplicatedExternalId = props.merchantArray.some((obj) => {
      return obj.merchantExternalIds.some((externalId) => {
        return externalId === merchantInputField.externalId;
      });
    });

    if (duplicatedMerchant) {
      setAddMerchantError("error");
      setAddMerchantErrorMsg(
        `Merchant with merchant id '${merchantInputField.merchantId}' already exists for configuration type.`
      );
    } else if (duplicatedExternalId) {
      setAddMerchantError("error");
      setAddMerchantErrorMsg(
        `External Id '${merchantInputField.externalId}' already exists for a merchant in this configuration type.`
      );
    } else if (isMerchantValid()) {
      const accessToken = oktaAuth.getAccessToken();
      setLoading(true);
      setOpenBackdrop(!openBackdrop);

      fetch(
        `${getDomain()}tenants/${props.tenantName}/configTypes/EMV/merchants`,
        {
          method: "post",
          body: JSON.stringify({
            merchantId: merchantInputField.merchantId,
            externalId: merchantInputField.externalId,
          }),
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
        .then((response) => {
          return response.json();
        })

        .then((object) => {
          if (object.status === "Success") {
            props.processMerchantList(merchantInputField);
            setLoading(false);
            setOpenBackdrop(false);
            setSaveStatus(true);

            setAddMerchantError("success");

            setMerchantInputField({ merchantId: "", externalId: "" });
            setMerchantErrorState({
              merchantIdError: false,
              externalIdError: false,
            });
          } else {
            return Promise.reject(object.error);
          }
        })
        .catch((error) => {
          setAddMerchantError("error");
          setAddMerchantErrorMsg(error.errorMessage);
        });
    }
  };

  /* These conditions are used to set the message confirmation when 
  a user adds a merchant 
  */
  if (addMerchantError === "success") {
    alert = (
      <Box className={`successMessage`} color="green">
        A new merchant is added
      </Box>
    );
  } else if (addMerchantError === "error") {
    alert = (
      <Box className={`errorMessage`} color="red">
        {addMerchantErrorMsg}
      </Box>
    );
  } else {
    alert = <Box>{addMerchantErrorMsg}</Box>;
  }

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={handleClose}
      id="addMerchantId"
      BackdropComponent={Backdrop}
    >
      <Box className={`${classes.paper} addMerchantModal`}>
        <Box height="5%" color="#FFF" padding={1} bgcolor="primary.main">
          Add Merchant
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="80%"
        >
          <form className={classes.formStyle}>
            <TextField
              required
              size="small"
              name="merchantId"
              label="Merchant Id"
              variant="outlined"
              id="merchantIdInput"
              value={merchantInputField.merchantId}
              className={`${classes.merchantId} merchantIdInput`}
              onChange={merchantHandler}
              error={merchantErrorState.merchantIdError}
              helperText={WarningError.merchantIdError(
                merchantErrorState.merchantIdError
              )}
              inputProps={{ maxLength: 255 }}
            />
            <TextField
              required
              size="small"
              name="externalId"
              label="Idemia Location Id"
              variant="outlined"
              id="externalIdInput"
              value={merchantInputField.externalId}
              className={`${classes.externalId} externalIdInput`}
              onChange={externalHandler}
              error={merchantErrorState.externalIdError}
              helperText={WarningError.externalIdError(
                merchantErrorState.externalIdError
              )}
              inputProps={{ maxLength: 10 }}
            />
          </form>
          <Box ml={3.5}>
          {loading && (
            <Backdrop className={classes.backdrop} open={openBackdrop}>
              <CircularProgress size={36} className={classes.buttonProgress} />
            </Backdrop>
          )}
            {alert}
            <Box mt="1rem">
              <Button
                variant="contained"
                color="Primary"
                className={`${classes.saveBtn} save`}
                onClick={saveMerchant}
                disabled={saveStatus}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="Cancel"
                className={`${classes.cancelBtn} close`}
                onClick={handleClose}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalAddMerchant;
