import { React, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useOktaAuth } from "@okta/okta-react";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import WarningError from "./WarningError";
import FormValidation from "./FormValidation";
import { getDomain } from "./Domain";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "30%",
    height: "50%",
    outline: "none",
  },
  addMerchantFont: {
    fontSize: 16,
  },
  formStyle: {
    marginLeft: "30px",
    marginTop: "20px",
  },
  merchantId: {
    width: "90%",
    height: "3.5rem",
    marginBottom: "1rem",
  },
  searchMerchantId: {
    width: "17%",
  },
  externalId: {
    width: "90%",
    height: "3.5rem",
    marginBottom: "1rem",
  },
  password: {
    width: "90%",
    height: "3.5rem",
    marginBottom: "1rem",
  },
  confirmPassword: {
    width: "90%",
    height: "3.5rem",
    marginBottom: "1rem",
  },
  saveBtn: {
    marginRight: "1rem",
    width: "5rem",
  },
  cancelBtn: {
    marginRight: "1rem",
    width: "5rem",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const NonEmvModalAddMerchant = (props) => {
  const classes = useStyles();
  const { oktaAuth } = useOktaAuth();

  let [nonEmvMerchant, setNonEmvMerchant] = useState({
    merchantId: "",
    externalId: "",
    password: "",
    confirmPassword: "",
  });

  const [nonEmvMerchantErrorState, setNonEmvMerchantErrorState] = useState({
    merchantIdError: false,
    externalIdError: false,
    passwordError: false,
    confirmPasswordError: false,
  });

  const [addMerchantError, setAddMerchantError] = useState("");
  const [addMerchantErrorMsg, setAddMerchantErrorMsg] = useState("");
  const [saveStatus, setSaveStatus] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [loading, setLoading] = useState(false);
  let alert = "";

  const handleClose = () => {
    setAddMerchantError(null);
    setAddMerchantErrorMsg("");
    setNonEmvMerchantErrorState({
      merchantIdError: false,
      externalIdError: false,
      confirmPasswordError: false,
    });
    setNonEmvMerchant({
      merchantId: "",
      externalId: "",
      password: "",
      confirmPassword: "",
    });
    setSaveStatus(false);
    props.handleClose();
  };

  const inputHandler = (e) => {
    setNonEmvMerchant({ ...nonEmvMerchant, [e.target.name]: e.target.value });
    setSaveStatus(false);
    setAddMerchantError(null);
    setAddMerchantErrorMsg("");

    if (e.target.value.length >= 0 && e.target.name === "merchantId") {
      setNonEmvMerchantErrorState({
        ...nonEmvMerchantErrorState,
        merchantIdError: false,
      });
    }

    if (e.target.value.length >= 0 && e.target.name === "externalId") {
      setNonEmvMerchantErrorState({
        ...nonEmvMerchantErrorState,
        externalIdError: false,
      });
    }

    if (e.target.value.length >= 0 && e.target.name === "password") {
      setNonEmvMerchantErrorState({
        ...nonEmvMerchantErrorState,
        passwordError: false,
      });
    }

    if (e.target.value.length >= 0 && e.target.name === "confirmPassword") {
      setNonEmvMerchantErrorState({
        ...nonEmvMerchantErrorState,
        confirmPasswordError: false,
      });
    }
  };

  const isNonEmvMerchantValid = (e) => {
    let output = false;

    if (
      FormValidation.merchantIdValid(nonEmvMerchant.merchantId) &&
      FormValidation.externalIdValid(nonEmvMerchant.externalId) &&
      nonEmvMerchant.password.length !== 0 &&
      nonEmvMerchant.confirmPassword === nonEmvMerchant.password
    ) {
      setNonEmvMerchantErrorState({
        ...nonEmvMerchantErrorState,
        confirmPasswordError: false,
      });
      output = true;
    } else {
      setNonEmvMerchantErrorState({
        ...nonEmvMerchantErrorState,
        merchantIdError: !FormValidation.merchantIdValid(
          nonEmvMerchant.merchantId
        ),
        externalIdError: !FormValidation.externalIdValid(
          nonEmvMerchant.externalId
        ),
        passwordError: nonEmvMerchant.password.length === 0,
        confirmPasswordError:
          nonEmvMerchant.confirmPassword !== nonEmvMerchant.password,
      });
    }
    return output;
  };

  const saveMerchant = () => {
    setAddMerchantError(null);
    setAddMerchantErrorMsg("");

    let duplicatedMerchant = props.nonEmvMerchantArray.some((obj) => {
      return obj.id === nonEmvMerchant.merchantId;
    });

    let duplicatedExternalId = props.nonEmvMerchantArray.some((obj) => {
      return obj.merchantExternalIds.some((externalId) => {
        return externalId === nonEmvMerchant.externalId;
      });
    });

    if (duplicatedMerchant) {
      setAddMerchantError("error");
      setAddMerchantErrorMsg(
        `Merchant with merchant id '${nonEmvMerchant.merchantId}' already exists for configuration type.`
      );
    } else if (duplicatedExternalId) {
      setAddMerchantError("error");
      setAddMerchantErrorMsg(
        `External Id '${nonEmvMerchant.externalId}' already exists for a merchant in this configuration type.`
      );
    } else if (isNonEmvMerchantValid()) {
      const accessToken = oktaAuth.getAccessToken();
      setLoading(true);
      setOpenBackdrop(!openBackdrop);
      fetch(
        `${getDomain()}tenants/${
          props.tenantName
        }/configTypes/NON-EMV/merchants`,
        {
          method: "post",
          body: JSON.stringify({
            merchantId: nonEmvMerchant.merchantId,
            externalId: nonEmvMerchant.externalId,
            password: nonEmvMerchant.password,
          }),
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
        .then((response) => {
          return response.json();
        })

        .then((object) => {
          if (object.status === "Success") {
            setSaveStatus(true);
            setLoading(false);
            setOpenBackdrop(false);
            setAddMerchantError("success");
            setAddMerchantErrorMsg('A new merchant is added');
            props.addNonEmvMerchant(nonEmvMerchant);

            setNonEmvMerchant({
              merchantId: "",
              externalId: "",
              password: "",
              confirmPassword: "",
            });

            setNonEmvMerchantErrorState({
              merchantIdError: false,
              externalIdError: false,
              confirmPasswordError: false,
            });
          } else {
            return Promise.reject(object.error);
          }
        })
        .catch((error) => {
          setAddMerchantError("error");
          setAddMerchantErrorMsg(error.errorMessage);
        });
    }
  };

  if (addMerchantError === "success") {
    alert = (
      <Box className={`successMessage`} color="green">
        {addMerchantErrorMsg}
      </Box>
    );
  } else if (addMerchantError === "error") {
    alert = (
      <Box className={`errorMessage`} color="red">
        {addMerchantErrorMsg}
      </Box>
    );
  }

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={handleClose}
      id="addNonMerchantId"
      BackdropComponent={Backdrop}
    >
      <Box className={`${classes.paper} addMerchantModal`}>
        <Box height="5%" color="#FFF" padding={1} bgcolor="primary.main">
          Add Merchant
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="86%"
        >
          <form className={classes.formStyle}>
            <TextField
              required
              size="small"
              name="merchantId"
              label="Merchant Id"
              variant="outlined"
              id="merchantIdInput"
              onChange={inputHandler}
              value={nonEmvMerchant.merchantId}
              className={`${classes.merchantId} merchantIdInput`}
              error={nonEmvMerchantErrorState.merchantIdError}
              helperText={WarningError.merchantIdError(
                nonEmvMerchantErrorState.merchantIdError
              )}
              autoComplete="new-merchantId"
              inputProps={{ maxLength: 255 }}
            />
            <TextField
              required
              size="small"
              name="externalId"
              label="Idemia Location Id"
              variant="outlined"
              id="externalIdInput"
              onChange={inputHandler}
              value={nonEmvMerchant.externalId}
              className={`${classes.externalId} externalIdInput`}
              error={nonEmvMerchantErrorState.externalIdError}
              helperText={WarningError.externalIdError(
                nonEmvMerchantErrorState.externalIdError
              )}
              inputProps={{ maxLength: 10 }}
            />
            <TextField
              required
              type="password"
              size="small"
              name="password"
              label="Password"
              variant="outlined"
              id="passwordInput"
              onChange={inputHandler}
              value={nonEmvMerchant.password}
              className={`${classes.password} passwordInput`}
              error={nonEmvMerchantErrorState.passwordError}
              helperText={WarningError.passwordError(
                nonEmvMerchantErrorState.passwordError
              )}
              autoComplete="new-password"
            />
            <TextField
              required
              type="password"
              size="small"
              name="confirmPassword"
              label="Confirm Password"
              variant="outlined"
              id="confirmPasswordInput"
              value={nonEmvMerchant.confirmPassword}
              onChange={inputHandler}
              className={`${classes.confirmPassword} confirmPasswordInput`}
              error={nonEmvMerchantErrorState.confirmPasswordError}
              helperText={WarningError.confirmPasswordError(
                nonEmvMerchantErrorState.confirmPasswordError
              )}
            />
          </form>
          <Box ml={3.5}>
            {alert}
            {loading && (
              <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress
                  size={36}
                  className={classes.buttonProgress}
                />
              </Backdrop>
            )}
            <Box mt="1rem">
              <Button
                variant="contained"
                color="Primary"
                className={`${classes.saveBtn} save`}
                onClick={saveMerchant}
                disabled={saveStatus}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="Cancel"
                className={`${classes.cancelBtn} close`}
                onClick={handleClose}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default NonEmvModalAddMerchant;
